import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import { useInfiniteSeries } from '../../hooks/series'
import styles from './Serie.module.css'
import 'swiper/css'
import 'swiper/css/navigation'
import { Fragment, useRef, useState } from 'react'
import { Swiper as SwiperCore } from 'swiper/types'
import { Serie as SerieType } from '../../types'
import classNames from 'classnames'
import ClipPreview from '../../components/ClipPreview'
import { useIsMobileScreen } from '../../hooks/screen'
import { smartSlug } from '../../utils/slug'
import Footer from '../../components/Footer'
import DividerGradient from '../../components/DividerGradient'

interface SwiperClipsProps {
  serie: SerieType
}

function SwiperClips({ serie }: SwiperClipsProps) {
  const swiperRef = useRef<SwiperCore>()
  const [slide, setSlide] = useState(0)
  const isMobile = useIsMobileScreen()
  return (
    <Fragment>
      {serie.clips.length > 3 && (
        <div className="mb-3 text-end me-1 d-flex justify-content-end">
          <div
            className="swiper-next-prev"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <i
              className={classNames('bi pointer bi-arrow-left', {
                'swiper-disabled': slide === 0,
              })}
            />
          </div>
          <div
            className="swiper-next-prev ms-3"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <i
              className={classNames('bi pointer bi-arrow-right', {
                'swiper-disabled':
                  slide ===
                  (isMobile ? serie.clips.length - 1 : serie.clips.length - 4),
              })}
            />
          </div>
        </div>
      )}
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          700: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          1000: {
            slidesPerView: 4,
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={16}
        onSlideChange={(e) => setSlide(e.activeIndex)}
      >
        {serie.clips.map((clip) => (
          <SwiperSlide key={clip.id}>
            <ClipPreview classCol={false} clip={clip} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Fragment>
  )
}

export default function Serie() {
  const { data } = useInfiniteSeries()
  const { i18n } = useTranslation()

  return (
    <Layout>
      <div className="w-100 bg-white">
        {/* <DividerGradient /> */}
        <div className={`${styles.BlockSeries}`}>
          {data!.pages.map((page, i) => (
            <div key={i}>
              {page.results.map((serie) => (
                <Fragment key={serie.id}>
                  <DividerGradient />
                  <div className={`${styles.SerieBlock} row`}>
                    <div className="col-md-6">
                      <div className="">
                        <LangLink
                          className="no-link d-flex align-items-center"
                          to={`/serie/${smartSlug(serie.id, serie.title.it)}`}
                        >
                          <h2 className={styles.TitleSerie}>
                            {serie.title[i18n.language]
                              ? serie.title[i18n.language]
                              : serie.title.it}
                          </h2>
                          <div className={styles.NumClips}>
                            {serie.clips_count} clip
                          </div>
                        </LangLink>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                      <div className={styles.Description}>
                        {serie.description[i18n.language]
                          ? serie.description[i18n.language]
                          : serie.description.it}
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <SwiperClips serie={serie} />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
