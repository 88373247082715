import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, Button, Offcanvas } from 'react-bootstrap'
import { ReactComponent as CloseCanvas } from '../../assets/close-canvas.svg'
import Select from 'react-select'
import { FacetRecord } from '../../hooks/clips'
import ArchiveTimeBrush from '../ArchiveTimeBrush'
import styles from './ClipFiltersCanvas.module.css'
import { Keyword } from '../../types'
import { useTranslation } from 'react-i18next'

export interface ClipFiltersCanvasProps {
  showCanvas: boolean
  count: number
  countFilter: number
  handleCloseCanvas: () => void
  filters: Record<string, any>
  setFilters(filters: Record<string, any>): void
  facets: {
    date__year: FacetRecord
  }
  keywords?: Keyword[]
  authors?: string[]
  positions?: string[]
  formats?: string[]
  titleCanvas?: string
}

const defaultFilters = {
  from_date: '',
  to_date: '',
  search: '',
  order: '-id',
  keywords: [],
  authors: [],
  positions: [],
  formats: [],
}

interface FiltersStateTypes {
  from_date: string
  to_date: string
  search: string
  keywords: string[]
  authors: string[]
  positions: string[]
  formats: string[]
  order: string
}

export default function ClipFiltersCanvas({
  showCanvas,
  count,
  countFilter,
  handleCloseCanvas,
  filters,
  setFilters,
  facets,
  keywords,
  authors,
  positions,
  formats,
  titleCanvas,
}: ClipFiltersCanvasProps) {
  const [filtersState, setFiltersState] = useState<FiltersStateTypes>({
    from_date: filters.from_date || '',
    to_date: filters.to_date || '',
    search: filters.search,
    keywords: filters.keywords || [],
    authors: filters.authors || [],
    positions: filters.positions || [],
    formats: filters.formats || [],
    order: filters.order || '-id',
  })

  const { i18n, t } = useTranslation()

  const fromYear = filtersState.from_date
    ? dayjs(filtersState.from_date).year()
    : null
  const toYear = filtersState.to_date
    ? dayjs(filtersState.to_date).year()
    : null

  const handleYearsChange = useCallback(
    ({ fromYear, toYear }: { fromYear: number; toYear: number }) => {
      setFiltersState({
        ...filtersState,
        from_date: dayjs(new Date(fromYear, 0, 1)).format('YYYY-MM-DD'),
        to_date: dayjs(new Date(toYear, 11, 31)).format('YYYY-MM-DD'),
      })
    },
    [filtersState]
  )

  const keywordsToUse = useMemo(() => {
    return keywords?.map((keyword) => ({
      label: keyword.value[i18n.language],
      value: String(keyword.id),
    }))
  }, [keywords, i18n.language])

  const authorsToUse = useMemo(() => {
    return authors?.map((author) => ({
      label: author,
      value: author,
    }))
  }, [authors])

  const selectedAuthors = useMemo(
    () => authorsToUse?.filter((d) => filtersState.authors.includes(d.value)),
    [authorsToUse, filtersState.authors]
  )

  const positionsToUse = useMemo(() => {
    return positions?.map((position) => ({
      label: position,
      value: position,
    }))
  }, [positions])

  const formatsTouse = useMemo(() => {
    return formats?.map((format) => ({
      label: format,
      value: format,
    }))
  }, [formats])

  const selectedKeywords = useMemo(
    () => keywordsToUse?.filter((d) => filtersState.keywords.includes(d.value)),
    [filtersState.keywords, keywordsToUse]
  )

  useEffect(() => {
    setFiltersState({
      ...filtersState,
      search: filters.search,
      authors: filters.authors,
      formats: filters.formats,
      keywords: filters.keywords,
      positions: filters.positions,
      from_date: filters.from_date,
      to_date: filters.to_date,
    })
  }, [filters])

  return (
    <Offcanvas placement="end" show={showCanvas} onHide={handleCloseCanvas}>
      <Offcanvas.Body className={styles.CanvasBody}>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className={styles.LabelInput}>
                  {titleCanvas ? titleCanvas : t('cerca')}
                </div>
                <div
                  onClick={handleCloseCanvas}
                  className="container-icon pointer"
                >
                  <i className="bi bi-x-lg pointer" />
                </div>
              </div>
              <input
                onChange={(e) => {
                  setFiltersState({ ...filtersState, search: e.target.value })
                }}
                placeholder={t('inserisci_ricerca') || ''}
                value={filtersState.search}
                className={styles.InputSearch}
              />
            </div>
            <div className={styles.BlockFilters}>
              <label className={styles.LabelInput}>{t('filtri')}</label>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={styles.AccordionHeader}>
                    {t('periodo')}
                    {(filters.from_date !== '' || filters.to_date !== '') && (
                      <span className={styles.FilterActive}>
                        {dayjs(filters.from_date).format('YYYY')} -{' '}
                        {dayjs(filters.to_date).format('YYYY')}
                      </span>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ArchiveTimeBrush
                      fromYear={fromYear}
                      toYear={toYear}
                      onYearsChange={handleYearsChange}
                      dataYear={facets.date__year}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className={styles.AccordionHeader}>
                    Keywords{' '}
                    {filters.keywords.length > 0 && (
                      <span className={styles.FilterActive}>
                        {filters.keywords.length} keywords
                      </span>
                    )}
                  </Accordion.Header>
                  <Accordion.Body className={styles.AccordionBody}>
                    <Select
                      value={selectedKeywords}
                      closeMenuOnSelect={false}
                      placeholder={t('place_keywords')}
                      classNamePrefix={'keywords-select'}
                      options={keywordsToUse}
                      onChange={(values) => {
                        if (values.length === 0) {
                          setFiltersState({
                            ...filtersState,
                            keywords: [],
                          })
                        } else {
                          setFiltersState({
                            ...filtersState,
                            keywords: values
                              .map((c) => c.value)
                              .filter(Boolean),
                          })
                        }
                      }}
                      isMulti
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className={styles.AccordionHeader}>
                    {t('autore')}
                    {filters.authors.length > 0 && (
                      <span className={styles.FilterActive}>
                        {filters.authors.length} {t('autori')}
                      </span>
                    )}
                  </Accordion.Header>
                  <Accordion.Body className={styles.AccordionBody}>
                    <Select
                      value={selectedAuthors}
                      closeMenuOnSelect={false}
                      placeholder={t('place_authors')}
                      classNamePrefix={'keywords-select'}
                      options={authorsToUse}
                      onChange={(values) => {
                        if (values.length === 0) {
                          setFiltersState({
                            ...filtersState,
                            authors: [],
                          })
                        } else {
                          setFiltersState({
                            ...filtersState,
                            authors: values.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      isMulti
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className={styles.AccordionHeader}>
                    {t('citta')}
                    {filters.positions.length > 0 && (
                      <span className={styles.FilterActive}>
                        {filters.positions.length} {t('cities')}
                      </span>
                    )}
                  </Accordion.Header>
                  <Accordion.Body className={styles.AccordionBody}>
                    <Select
                      value={positionsToUse?.filter((d) =>
                        filtersState.positions.includes(d.value)
                      )}
                      closeMenuOnSelect={false}
                      placeholder={t('place_cities')}
                      classNamePrefix={'keywords-select'}
                      options={positionsToUse}
                      onChange={(values) => {
                        if (values.length === 0) {
                          setFiltersState({
                            ...filtersState,
                            positions: [],
                          })
                        } else {
                          setFiltersState({
                            ...filtersState,
                            positions: values
                              .map((c) => c.value)
                              .filter(Boolean),
                          })
                        }
                      }}
                      isMulti
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className={styles.AccordionHeader}>
                    {t('formato')}
                    {filters.formats.length > 0 && (
                      <span className={styles.FilterActive}>
                        {filters.formats.length} {t('formati')}
                      </span>
                    )}
                  </Accordion.Header>
                  <Accordion.Body className={styles.AccordionBody}>
                    <Select
                      value={formatsTouse?.filter((d) =>
                        filtersState.formats.includes(d.value)
                      )}
                      closeMenuOnSelect={false}
                      placeholder={t('place_formats')}
                      classNamePrefix={'keywords-select'}
                      options={formatsTouse}
                      onChange={(values) => {
                        if (values.length === 0) {
                          setFiltersState({
                            ...filtersState,
                            formats: [],
                          })
                        } else {
                          setFiltersState({
                            ...filtersState,
                            formats: values.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      isMulti
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className={styles.BlockOrdinamento}>
                <label className={styles.LabelInput}>{t('ordinamento')}</label>
                <div>
                  <select
                    className={styles.SelectSearch}
                    onChange={(e) => {
                      setFiltersState({
                        ...filtersState,
                        order: e.target.value,
                      })
                    }}
                    value={filtersState.order}
                  >
                    <option value="-id">{t('data_inserimento')}</option>
                    <option value="date">{t('data_crescente')}</option>
                    <option value="-date">{t('data_descrescente')}</option>
                    <option value="author__name">{t('autore')}</option>
                    <option value="city">{t('city')}</option>
                    <option value={'title__'+i18n.language}>{t('title')}</option>
                    {/* <option value="rand">{t('random')}</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-end">
              {countFilter !== count && (
                <div className={styles.CountClips}>
                  {countFilter} {t('di')} {count} Clip
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between mt-2">
              <Button
                onClick={() => {
                  setFiltersState({ ...defaultFilters })
                  setFilters({ ...defaultFilters })
                  handleCloseCanvas()
                }}
                className="button-default"
              >
                {t('rimuovi_filtri')}
              </Button>
              <div className="d-flex">
                <Button
                  className="button-default"
                  type="submit"
                  onClick={() => {
                    setFilters({ ...filtersState })
                    handleCloseCanvas()
                  }}
                >
                  {t('filtra')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
