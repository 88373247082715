import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import styles from '../Serie/Serie.module.css'
import 'swiper/css'
import 'swiper/css/navigation'
import { Fragment, useRef, useState } from 'react'
import { Swiper as SwiperCore } from 'swiper/types'
import { Territorio as TerritorioType } from '../../types'
import classNames from 'classnames'
import ClipPreview from '../../components/ClipPreview'
import { useIsMobileScreen } from '../../hooks/screen'
import { smartSlug } from '../../utils/slug'
import Footer from '../../components/Footer'
import DividerGradient from '../../components/DividerGradient'
import { useInfiniteTerritori } from '../../hooks/territories'
import { useContent } from '../../hooks/contents'

interface SwiperClipsProps {
  territorio: TerritorioType
}

function SwiperClips({ territorio }: SwiperClipsProps) {
  const swiperRef = useRef<SwiperCore>()
  const [slide, setSlide] = useState(0)
  const isMobile = useIsMobileScreen()
  return (
    <Fragment>
      {territorio.clips.length > 3 && (
        <div className="mb-3 text-end me-1 d-flex justify-content-end">
          <div
            className="swiper-next-prev"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <i
              className={classNames('bi pointer bi-arrow-left', {
                'swiper-disabled': slide === 0,
              })}
            />
          </div>
          <div
            className="swiper-next-prev ms-3"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <i
              className={classNames('bi pointer bi-arrow-right', {
                'swiper-disabled':
                  slide ===
                  (isMobile
                    ? territorio.clips.length - 1
                    : territorio.clips.length - 4),
              })}
            />
          </div>
        </div>
      )}
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          700: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          1000: {
            slidesPerView: 4,
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={16}
        onSlideChange={(e) => setSlide(e.activeIndex)}
      >
        {territorio.clips.map((clip) => (
          <SwiperSlide key={clip.id}>
            <ClipPreview classCol={false} clip={clip} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Fragment>
  )
}

export default function Territori() {
  const { data } = useInfiniteTerritori()
  const { t, i18n } = useTranslation()
  const territoriText = useContent('territori')

  return (
    <Layout>
      <div className="w-100 bg-white">
        <DividerGradient />
        <div className={styles.TerritorioBlock}>
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{t('territories_page_title')}</h1>
              <p
                className={styles.Description}
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: territoriText.text[i18n.language],
                }}
              />
            </div>
          </div>
        </div>
        {/* <DividerGradient /> */}
        <div className={`${styles.BlockSeries}`}>
          {data!.pages.map((page, i) => (
            <div key={i}>
              {page.results.map((territorio) => (
                <Fragment key={territorio.id}>
                  <DividerGradient />
                  <div className={`${styles.SerieBlock} row`}>
                    <div className="col-md-6">
                      <div className="">
                        <LangLink
                          className="no-link d-flex align-items-center"
                          to={`/territories/${smartSlug(
                            territorio.id,
                            territorio.title.it
                          )}`}
                        >
                          <h2 className={styles.TitleSerie}>
                            {territorio.title[i18n.language]
                              ? territorio.title[i18n.language]
                              : territorio.title.it}
                          </h2>
                          <div className={styles.NumClips}>
                            {territorio.clips_count} clip
                          </div>
                        </LangLink>
                      </div>
                      <div className={styles.ExtraText}>
                        {territorio.credits[i18n.language]
                          ? territorio.credits[i18n.language]
                          : territorio.credits.it}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                      <div className={styles.Description}>
                        {territorio.description[i18n.language]
                          ? territorio.description[i18n.language]
                          : territorio.description.it}
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <SwiperClips territorio={territorio} />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
