import axios from 'axios'
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { BlockHome, Slide } from '../types'
import { serializeQueryParams } from './utils'

export async function getBlocksHome(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/blocks-home/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as BlockHome[]
}

export function prefetchBlocksHome(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['blocks-home', params], ({ signal }) =>
    getBlocksHome(params, signal)
  )
}

export function usePrefetchBlocksHome(params: Record<string, any> = {}) {
  const queryClient = useQueryClient()
  prefetchBlocksHome(queryClient, params)
}

export function useBlocksHome(params: Record<string, any> = {}) {
  return useQuery(
    ['blocks-home', params],
    ({ signal }) => getBlocksHome(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}
