import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DividerGradient from '../../components/DividerGradient'
import Footer from '../../components/Footer'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import { useViteArchivio } from '../../hooks/vite-archivio'
import { smartSlug } from '../../utils/slug'
import styles from './ViteArchivio.module.css'

export default function ViteArchivio() {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const vite = useViteArchivio()
  return (
    <Layout>
      <div className={styles.BlockTitle}>
        <div className="w-100">
          <DividerGradient />
          <div className="d-flex d-mb-block align-items-center w-100 justify-content-between p-container-top">
            <h2 className="title-page-with-padding d-flex align-items-center">
              {t('vite_d_archivo_page_title')}
            </h2>
          </div>
        </div>
      </div>
      <div className={`${styles.BlockVite}`}>
        <div className="row">
          {vite.map((vita) => (
            <div key={vita.id} className={'col-md-4 mb-3'}>
              <LangLink
                className="no-link"
                to={`/vite-archivio/${smartSlug(vita.id, vita.title.it)}`}
              >
                <div className="w-100 h-100">
                  <div
                    className={styles.Image}
                    style={{ backgroundImage: `url('${vita.cover_image}')` }}
                  >
                    <div className={styles.Title}>
                      {vita.title[i18n.language]}
                    </div>
                  </div>
                </div>
              </LangLink>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
