import { ReactNode } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LANGS } from '../../i18n'
import { useMobileMenuToggler } from '../../MobileMenu'
import ChangeLangLink from '../ChangeLangLink'
import NavLangLink from '../NavLangLink'
import SuggestionAutocomplete from '../SuggestionAutocomplete'

interface TopBarProps {
  right?: ReactNode
  left?: ReactNode
}

export default function TopBar({
  right = <div style={{ minWidth: 32 }}></div>,
  left,
}: TopBarProps) {
  const toggleMobileMenu = useMobileMenuToggler()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <Navbar className="navbar" bg="white" expand="xl">
      <div className="d-flex w-100 flex-xl-row align-items-center">
        <div className="w-100 d-flex align-items-center justify-content-between d-block d-xl-none">
          <div onClick={() => toggleMobileMenu()}>
            <i className="bi fs-2 bi-list" />
          </div>
          <NavLangLink end className="navbar-memory-name" to="/">
            Memoryscapes
          </NavLangLink>
          {right}
        </div>
        <Navbar.Brand className="d-none d-xl-block">
          <NavLangLink end className="navbar-memory-name" to="/">
            Memoryscapes
          </NavLangLink>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLangLink end className="nav-link me-3" to="/archive">
              {t('archive_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/serie">
              {t('serie_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/vite-archivio">
              {t('vite_d_archivo_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/territories">
              {t('territories_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/extra">
              {t('extra_archive_page')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/project">
              {t('chi_siamo_archive_page')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/donate">
              {t('dona_title_page')}
            </NavLangLink>
            <SuggestionAutocomplete
              onSubmitSearch={(text) =>
                navigate(`/${i18n.language}/archive/?search=${text}`)
              }
              defaultInputValue={''}
            />
            {/* <NavLangLink end className="nav-link me-3" to="/donate">
              {t('chi_siamo_archive_page')}
            </NavLangLink> */}
          </Nav>
          <Nav className="d-flex align-items-center">
            
            {LANGS.map((lang) => (
              <ChangeLangLink
                key={lang}
                className="text-capitalize nav-link"
                lang={lang}
                activeLangClassName="text-primary-100"
              >
                {lang}
              </ChangeLangLink>
            ))}
            <Nav.Link
              href="https://www.facebook.com/archiviohomemovies/"
              target={'_blank'}
              rel="noopener"
              className={'icon-social ms-3 me-2'}
            >
              <i className="bi bi-facebook" />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/archiviohomemovies/"
              target={'_blank'}
              className={'icon-social'}
              rel="noopener"
            >
              <i className="bi bi-instagram" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
