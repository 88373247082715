import Layout from './Layout'

export default function NotFound() {
  return (
    <Layout>
      <div className="text-center p-4">
        <h1>404 Page Not Found</h1>
      </div>
    </Layout>
  )
}