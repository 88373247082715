import axios from 'axios'
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { Keyword } from '../types'
import { serializeQueryParams } from './utils'

export async function getKeywords(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/keywords/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as Keyword[]
}

export function prefetchKeywords(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['keywords', params], ({ signal }) =>
    getKeywords(params, signal)
  )
}

export function usePrefetchKeywords(params: Record<string, any> = {}) {
  const queryClient = useQueryClient()
  prefetchKeywords(queryClient, params)
}

export function useKeywords(params: Record<string, any> = {}) {
  return useQuery(
    ['keywords', params],
    ({ signal }) => getKeywords(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}
