import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { serializeQueryParams } from './utils'

export async function getAuthors(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/authors/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as string[]
}

export function useAuthors(params: Record<string, any> = {}) {
  return useQuery(
    ['authors', params],
    ({ signal }) => getAuthors(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}
