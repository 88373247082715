import { lazy, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  Location,
} from 'react-router-dom'
import NavigationWrapper from './components/NavigationWrapper'
import NotFound from './components/NotFound'
import { DEFAULT_LANG, getLangFromParam, LANGS } from './i18n'
import { LateOnClientProvider } from './LateOnClient'
import imagePlace from './assets/memory-place.jpg'
import { MobileMenu, MobileMenuProvider } from './MobileMenu'
// Critical Pages
import Home from './pages/Home'
import About from './pages/About'
import Serie from './pages/Serie'
import ViteArchivio from './pages/ViteArchivio'
import ClipDetail from './pages/ClipDetail'
import ViteArchivioDetail from './pages/ViteArchivioDetail'
import Donate from './pages/Donate'
import Analytics from './components/Analytics'
import CookieConsent from './pages/CookieConsent'
import Territories from './pages/Territories'
import TerritoryDetail from './pages/TerritoryDetail'
import Extra from './pages/Extra'
import ExtraDetail from './pages/ExtraDetail'

function AvailablesLang() {
  const { lang } = useParams()
  if (LANGS.includes(lang!)) {
    return <Outlet />
  }
  return <NotFound />
}

// NOTE: This sync lang when changed from push state navigation
// (user press back, forward history)
// This is only need client side
function SyncLang() {
  const { i18n } = useTranslation()
  const params = useParams()
  const lang = params.lang ?? DEFAULT_LANG

  useEffect(() => {
    const memoryLang = i18n.language
    if (memoryLang !== lang) {
      const nextLang = getLangFromParam(lang)
      if (nextLang !== memoryLang) {
        i18n.changeLanguage(nextLang)
      }
    }
  }, [lang, i18n])

  // Seo Language
  const seoLang = getLangFromParam(lang)

  return (
    <Helmet>
      <html lang={seoLang} />
    </Helmet>
  )
}

// Code Splittings Pages
const Archive = lazy(() => import('./pages/Archive'))
const SerieDetail = lazy(() => import('./pages/SerieDetail'))

function App() {
  const location = useLocation()
  const prevLocation = useRef<Location | null>(null)

  useEffect(() => {
    prevLocation.current = location
  }, [location])

  // NOTE: Avoid "modal" model when no prev location
  // when user hit refresh page on modal should see non-modal version
  let backgroundLocation: Location | undefined
  if (
    (location as any).state?.backgroundLocation &&
    prevLocation.current !== null
  ) {
    backgroundLocation = (location as any).state.backgroundLocation
  }

  return (
    <LateOnClientProvider>
      <MobileMenuProvider>
        <Helmet>
          <title>Memoryscapes - Archivio</title>
          <meta name="description" content="Il cinema privato online" />
          <meta property="og:title" content={`Memoryscapes - Archivio`} />
          <meta
            property="og:description"
            content={`Il cinema privato online`}
          />
          <meta property="og:image" content={`${imagePlace}`} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={`Memoryscapes - Archivio`} />
          <meta
            name="twitter:description"
            content={`Il cinema privato online`}
          />
          <meta name="twitter:image" content={`${imagePlace}`} />
        </Helmet>
        <Routes location={backgroundLocation ?? location}>
          <Route path={':lang/*'} element={<SyncLang />} />
          <Route path="*" element={<SyncLang />} />
        </Routes>
        <Routes location={backgroundLocation ?? location}>
          <Route element={<NavigationWrapper />}>
            <Route index element={<Home />} />
            <Route path={':lang/*'} element={<AvailablesLang />}>
              <Route index element={<Home />} />
              <Route path="project" element={<About />} />
              <Route path="archive" element={<Archive />} />
              <Route path="vite-archivio" element={<ViteArchivio />} />
              <Route
                path="vite-archivio/:slug"
                element={<ViteArchivioDetail />}
              />
              <Route
                path="clips/:slug"
                element={
                  <NavigationWrapper>
                    <ClipDetail />
                  </NavigationWrapper>
                }
              />
              <Route path="serie/:slug" element={<SerieDetail />} />
              <Route path="serie" element={<Serie />} />
              <Route path="extra" element={<Extra />} />
              <Route path="extra/:slug" element={<ExtraDetail />} />
              <Route path="territories" element={<Territories />} />
              <Route path='territories/:slug' element={<TerritoryDetail />} />
              <Route path="donate" element={<Donate />} />
              <Route path="cookie-consent" element={<CookieConsent />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
        <MobileMenu />
        <Analytics />
      </MobileMenuProvider>
    </LateOnClientProvider>
  )
}

export default App
