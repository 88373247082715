import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { PaginatedDJResponse, Territorio } from '../types'
import { serializeQueryParams, getNextPageParam } from './utils'

export async function getTerritories(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/territories/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as PaginatedDJResponse<Territorio>
}

export async function getTerritorio(
  idOrSlug: string | number,
  signal?: AbortSignal
) {
  return (await axios.get(`/api/territories/${idOrSlug}/`, { signal }))
    .data as Territorio
}

export function useTerritorio(idOrSlug: string | number) {
  return useQuery(['territorio', idOrSlug], ({ signal }) =>
    getTerritorio(idOrSlug, signal)
  ).data!
}

export function useInfiniteTerritori(params: Record<string, any> = {}) {
  return useInfiniteQuery(
    ['infiniteTerritories', params],
    ({ signal, pageParam }) =>
      getTerritories(
        {
          ...params,
          ...pageParam,
        },
        signal
      ),
    {
      keepPreviousData: true,
      getNextPageParam,
    }
  )
}
