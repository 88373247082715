import { Fragment, Suspense, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useParams } from 'react-router-dom'
import ClipPreview from '../../components/ClipPreview'
import { Swiper as SwiperCore } from 'swiper/types'
import orderBy from 'lodash/orderBy'
import 'swiper/css'
import 'swiper/css/navigation'
import { Clip, Clip as ClipType } from '../../types'
import Layout from '../../components/Layout'
import styles from './ViteArchivioDetail.module.css'
import { useIsMobileScreen } from '../../hooks/screen'
import classNames from 'classnames'
import { useVitaArchivio } from '../../hooks/vite-archivio'
import { parseSmartSlug } from '../../utils/slug'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import dayjs from 'dayjs'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { useIsLateOnClient } from '../../LateOnClient'
import { keyBy } from 'lodash'
import DividerGradient from '../../components/DividerGradient'

interface SwiperClipsProps {
  clips: ClipType[]
}

function SwiperClipsTimeline({ clips }: SwiperClipsProps) {
  const swiperRef = useRef<SwiperCore>()
  const [slide, setSlide] = useState(0)
  const { i18n } = useTranslation()
  const clipsWithoutNull = clips.filter((d) => d.date)
  const clipsToUse = orderBy(clipsWithoutNull, 'date')
  const minDate = Number(dayjs(minBy(clips, 'date')?.date).format('YYYY'))
  const maxDate = Number(dayjs(maxBy(clips, 'date')?.date).format('YYYY'))

  return (
    <div className="px-3 px-md-0">
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          600: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          1000: {
            slidesPerView: 3.7,
          },
        }}
        centeredSlides
        loopFillGroupWithBlank={false}
        loop
        parallax
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={16}
        onSlideChange={(e) => setSlide(e.realIndex)}
      >
        {clipsToUse.map((clip, i) => (
          <SwiperSlide key={clip.id}>
            <div className={slide !== i ? styles.OpacityClip : ''}>
              <ClipPreview
                showYear={false}
                showInfo={false}
                showHover={false}
                classCol={false}
                clip={clip}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-3 mb-5 d-md-block px-md-4">
        <div className='d-flex align-items-center'>
          <div>
            <i
              className={classNames(
                'bi pointer swiper-next-prev bi-arrow-left'
              )}
              onClick={() => swiperRef.current?.slidePrev()}
            />
          </div>
          <div className={styles.BorderTimeline}></div>
          <div>
            <i
              className={classNames(
                'bi ms-2 ms-md-0 pointer swiper-next-prev bi-arrow-right'
              )}
              onClick={() => swiperRef.current?.slideNext()}
            />
          </div>
        </div>
        <div className={styles.ContainerYearsTimeline}>
          {(() => {
            let div = []
            for (let year = minDate; year <= maxDate; year++) {
              const date = `${year}-01-01`
              const dateIndex = clipsToUse.findIndex(function (clip) {
                return clip.date == date
              })
              const haveDate = dateIndex !== -1
              div.push(
                <div
                  key={year}
                  onClick={() => {
                    if (haveDate) {
                      swiperRef.current?.slideToLoop(dateIndex)
                      setSlide(dateIndex)
                    }
                  }}
                  className={classNames(
                    'd-flex flex-column align-items-center',
                    {
                      pointer: haveDate,
                    }
                  )}
                >
                  {haveDate && clipsToUse[dateIndex] ? (
                    <div
                      className={
                        year ===
                        Number(dayjs(clipsToUse[slide].date).format('YYYY'))
                          ? styles.ItemTimelineActive
                          : styles.ItemTimelineWithDate
                      }
                    >{year}</div>
                  ) : (
                    <>
                    <div className={styles.PlaceHolderTimeline}></div>
                    <div className={styles.YearTimeline}>{year}</div>
                    </>
                  )}
                  
                </div>
              )
            }
            return div
          })()}
        </div>
      </div>
      <div className="row mt-2 mt-md-4 pb-4">
        <div className="offset-md-3 col-md-6">
          <h3 className={styles.TitleTimeline}>
            {clipsToUse[slide].title[i18n.language]}
          </h3>
          <div className={styles.DescriptionSlide}>
            {clipsToUse[slide].description[i18n.language]}
          </div>
        </div>
      </div>
    </div>
  )
}

export function SwiperClips({ clips }: SwiperClipsProps) {
  const swiperRef = useRef<SwiperCore>()
  const [slide, setSlide] = useState(0)
  const isMobile = useIsMobileScreen()
  return (
    <div>
      {clips.length > 4 && (
        <div className="d-flex justify-content-end mb-3">
          <i
            className={classNames('bi swiper-next-prev pointer bi-arrow-left', {
              'swiper-disabled': slide === 0,
            })}
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <i
            className={classNames(
              'bi swiper-next-prev pointer ms-3 bi-arrow-right',
              {
                'swiper-disabled':
                  slide === (isMobile ? clips.length - 1 : clips.length - 4),
              }
            )}
            onClick={() => swiperRef.current?.slideNext()}
          />
        </div>
      )}
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          700: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          1000: {
            slidesPerView: 4,
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={0}
        onSlideChange={(e) => setSlide(e.activeIndex)}
      >
        {clips.map((clip) => (
          <SwiperSlide key={clip.id}>
            <ClipPreview classCol={false} marginRight={true} clip={clip} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

interface BlockClipsSectionProps {
  clips: Clip[]
}

export function BlockClipsSection({ clips }: BlockClipsSectionProps) {
  return (
    <div className="row mt-5">
      <SwiperClips clips={clips ?? []} />
    </div>
  )
}

function BlockClipsSectionTimeline({ clips }: BlockClipsSectionProps) {
  return <SwiperClipsTimeline clips={clips ?? []} />
}

export default function ViteArchivioDetail() {
  const { slug } = useParams()
  const { i18n } = useTranslation()
  const vitaID = parseSmartSlug(slug!)
  const vita = useVitaArchivio(vitaID)
  const isLateOnClient = useIsLateOnClient()
  return (
    <Layout>
      <Helmet>
        <title>Memoryscapes - {`${vita.title['it']}`}</title>
        <meta name="description" content={`${vita.description['it']}`} />
        <meta property="og:title" content={`${vita.title['it']}`} />
        <meta property="og:description" content={`${vita.description['it']}`} />
        <meta property="og:image" content={`${vita.cover_image}`} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Memoryscapes - ${vita.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${vita.description['it']}`}
        />
        <meta name="twitter:image" content={`${vita.cover_image}`} />
      </Helmet>
      <div className={`container-fluid ${styles.BlockTop}`}>
        <div className="row">
          <div className="col-md-6">
            <div className={styles.Title}>{vita.title[i18n.language]}</div>
          </div>
          <div className="col-md-6">
            <div className={styles.Description}>
              {vita.description[i18n.language]}
            </div>
          </div>
        </div>
      </div>
      <div>
        {orderBy(vita?.blocks, 'order').map((block, i) => (
          <Fragment key={block.id}>
            {i !== 0 && <DividerGradient />}
            {block.type === 'text-slider' && block.clips.length > 0 ? (
              <div
                className={classNames('pb-4 pt-4', {
                  'content-page': i % 2 !== 0,
                })}
              >
                <div
                  className={classNames('mb-3', {
                    'content-page': i % 2 === 0,
                  })}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className={styles.TitleSectionVita}>
                        {block.title[i18n.language]}
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <div className={styles.DescriptionVita}>
                        {block.description[i18n.language]}
                      </div>
                    </div>
                  </div>
                  <BlockClipsSection clips={block.clips} />
                </div>
              </div>
            ) : (
              block.clips.length > 0 && (
                <div className="mb-3 bg-white overflow-hidden pt-5 pb-5">
                  {isLateOnClient && (
                    <Suspense fallback={<div />}>
                      <BlockClipsSectionTimeline clips={block.clips} />
                    </Suspense>
                  )}
                </div>
              )
            )}
          </Fragment>
        ))}
      </div>

      <Footer />
    </Layout>
  )
}
