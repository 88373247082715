import styles from './BlocksHomeClips.module.css'
import { useInfiniteClips } from '../../hooks/clips'
import { useMemo } from 'react'
import ClipPreview from '../ClipPreview'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { createSearchParams, Link } from 'react-router-dom'
import { useBlocksHome } from '../../hooks/blocks-home'
import { BlockHome } from '../../types'

interface BlockHomeProps {
  block: BlockHome
}

export function BlockClipsNormal({ block }: BlockHomeProps) {
  const params = useMemo(() => {
    return {
      page_size: 8,
      page: 1,
      keywords: block.keywords,
      authors: block.authors,
      formats: block.formats,
      date_from: block.date_from,
      date_to: block.date_to,
      order: 'rand',
    }
  }, [])
  const clips = useInfiniteClips(params)
  const { i18n } = useTranslation()

  const keywordsParams = createSearchParams({
    keywords: block?.keywords_data.map((k) => String(k.id)),
  }).toString()

  const authorsParams = createSearchParams({
    authors: block?.authors_data.map((k) => String(k.name)),
  }).toString()

  const formatsParams = createSearchParams({
    formats: block?.formats.map((k) => k),
  }).toString()

  const dateFromParam = createSearchParams({
    from_date: block?.date_from ?? '',
  }).toString()

  const dateToParam = createSearchParams({
    to_date: block?.date_to ?? '',
  }).toString()

  const linkToArchive = `/${i18n.language}/archive?${keywordsParams}&${authorsParams}&${formatsParams}&${dateFromParam}&${dateToParam}`

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h4 className={styles.TitleSection}>{block.title[i18n.language]}</h4>
      </div>
      <hr className="primary-hr" />
      <div className="row">
        <div className="col-md-12">{block.description[i18n.language]}</div>
      </div>
      <div className="mt-3">
        <Button
          as={Link as any}
          variant="dark"
          className={styles.ButtonOutlinePrimary}
          to={linkToArchive}
        >
          Vedi tutte le clip
        </Button>
      </div>
      <div className="row mt-5 pb-5">
        {clips.data?.pages[0].results.map((clip, i) => (
          <ClipPreview clip={clip} key={i} />
        ))}
      </div>
    </div>
  )
}

export function BlockClipsCentered({ block }: BlockHomeProps) {
  const params = useMemo(() => {
    return {
      page_size: 8,
      page: 1,
      keywords: block.keywords,
      authors: block.authors,
      formats: block.formats,
      date_from: block.date_from,
      date_to: block.date_to,
      order: 'rand',
    }
  }, [])
  const clips = useInfiniteClips(params)
  const { i18n, t } = useTranslation()

  const keywordsParams = createSearchParams({
    keywords: block?.keywords_data.map((k) => String(k.id)),
  }).toString()

  const authorsParams = createSearchParams({
    authors: block?.authors_data.map((k) => String(k.name)),
  }).toString()

  const formatsParams = createSearchParams({
    formats: block?.formats.map((k) => k),
  }).toString()

  const dateFromParam = createSearchParams({
    from_date: block?.date_from ?? '',
  }).toString()

  const dateToParam = createSearchParams({
    to_date: block?.date_to ?? '',
  }).toString()

  const linkToArchive = `/${i18n.language}/archive?${keywordsParams}&${authorsParams}&${formatsParams}&${dateFromParam}&${dateToParam}`

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h4 className={styles.TitleSection}>{block.title[i18n.language]}</h4>
          <div className="mt-3 mb-3">
            {block?.keywords_data.map((k) => (
              <div key={k.id} className='pill-primary'>
                {k.value[i18n.language]}
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Description}>
            {block.description[i18n.language]}
          </div>
          <Button
            as={Link as any}
            className={`${styles.Button} mb-3 mb-md-0`}
            to={linkToArchive}
            variant={'light'}
          >
            {t('vedi_tutte_le_clip')}
          </Button>
        </div>
      </div>
      <div className="row mt-5 pb-5">
        {clips.data?.pages[0].results.map((clip, i) => (
          <ClipPreview hoverWhite clip={clip} key={i} />
        ))}
      </div>
    </div>
  )
}

export default function BlocksHomeClips() {
  const blocks = useBlocksHome()
  return (
    <div>
      {blocks.map((block) =>
        block.type === 'centered' || !block.type ? (
          <BlockClipsCentered block={block} key={block.id} />
        ) : (
          <BlockClipsNormal block={block} key={block.id} />
        )
      )}
    </div>
  )
}
