import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { ClipDetail } from '../../types'
import styles from './FullClip.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsMobileScreen } from '../../hooks/screen'
import classNames from 'classnames'
import LangLink from '../LangLink'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { RequestContext } from '../../RequestContext'
import { smartSlug } from '../../utils/slug'

interface FullClipProps {
  clip: ClipDetail
  showBack?: boolean
  showTop?: boolean
}

export default function FullClip({
  clip,
  showBack = true,
  showTop = true,
}: FullClipProps) {
  const location = useLocation()
  const [showPreview, setShowPreview] = useState(true)
  const [paused, setPaused] = useState(true)
  const [ended, setEnded] = useState(false)
  const videoRef = useRef<any>(null)
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handlePlayerPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setPaused(true)
    }
  }

  const handlePlayerPlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setPaused(false)
      setEnded(false)
      setShowPreview(false)
    }
  }

  const isMobile = useIsMobileScreen()
  const reqInfo = useContext(RequestContext)
  const shareUrl = `${reqInfo.protocol}://${reqInfo.host}${location.pathname}`

  // avoid right click on video
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (e: any) => {
        e.preventDefault()
      })
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('contextmenu', (e: any) => {
          e.preventDefault()
        })
      }
    }
  }, [videoRef])

  return (
    <div className={styles.VimeoContainer}>
      <div
        className={classNames(`${styles.Preview}`, {
          'd-none': !showPreview,
        })}
        style={{ backgroundImage: `url(${clip.image_preview})` }}
      >
      </div>
      <video
        controlsList="nodownload"
        onEnded={() => {
          handlePlayerPause()
          setEnded(true)
        }}
        onClick={() => {
          if (paused) {
            handlePlayerPlay()
          } else {
            handlePlayerPause()
          }
        }}
        ref={videoRef}
        className={classNames(`${styles.Video}`, {})}
        src={clip.file_url_vimeo}
        width={'100%'}
        controls={paused === false ? true : false}
      >
        {/* <source src={clip.file_url_vimeo} type="video/mp4" /> */}
      </video>

      {showBack && (
        <div
          style={{ top: isMobile ? 20 : 20, left: isMobile ? 20 : 20 }}
          className="position-absolute pointer container-icon text-dark bg-white"
          onClick={goBack}
        >
          <i className="bi fs-5 bi-arrow-left" />
        </div>
      )}
      {paused && !ended && (
        <div className={`${styles.PlayIcon}`} onClick={handlePlayerPlay}>
          <i
            className="text-primary-100 bi bi-play-circle-fill"
            style={{ fontSize: 60 }}
          />
        </div>
      )}
      {ended && paused && (
        <div className={`${styles.PlayIcon}`} onClick={handlePlayerPlay}>
          <i
            className="text-primary-100 bi bi-play-circle-fill"
            style={{ fontSize: 60 }}
          />
        </div>
      )}
      {((paused && !ended) || isMobile) && (
        <div className={`${styles.BgClip}`}>
          <div
            style={{ opacity: showTop ? 1 : 0, transition: 'all 0.3s linear' }}
            className={`${styles.BlockInfoUp} row`}
          >
            <div className="col-md-12 mb-4">
              <h2 className={styles.TitleUp}>{clip.title[i18n.language]}</h2>
            </div>
            <div className="col-md-6">
              <div className={styles.InfoClipUp}>
                <div className="d-flex">
                  <div className={styles.LabelInfo}>{t('autore')}</div>
                  <LangLink
                    className="link-filters"
                    to={`/archive/?authors=${clip.author_data?.name}`}
                  >
                    {clip.author ? clip.author_data?.name : ''}
                  </LangLink>
                </div>
                <div className="d-flex">
                  <div className={styles.LabelInfo}>{t('tipologia')}</div>
                  <LangLink
                    className="link-filters"
                    to={`/archive/?formats=${clip.format}`}
                  >
                    {clip.format}
                  </LangLink>{' '}
                  - {clip.archive}
                </div>
                {clip.city && (
                  <div className="d-flex">
                    <div className={styles.LabelInfo}>{t('luogo')}</div>
                    <LangLink
                      className="link-filters"
                      to={`/archive/?positions=${clip.city}`}
                    >
                      {' '}
                      {clip.city}
                    </LangLink>{' '}
                  </div>
                )}
                {clip.date && (
                  <div className="d-flex">
                    <div className={styles.LabelInfo}>{t('anno')}</div>
                    <LangLink
                      className="link-filters"
                      to={`/archive/?from_date=${dayjs(clip.date).format(
                        'YYYY'
                      )}-01-01&to_date=${dayjs(clip.date).format(
                        'YYYY'
                      )}-12-31`}
                    >
                      {dayjs(clip.date).format('YYYY')}
                    </LangLink>
                  </div>
                )}
              </div>
              <div className='d-flex flex-wrap align-items-center mt-2'>
                {clip?.related_clips_serie.length > 0 && (
                  <div className="text-white d-flex">
                    {clip.related_clips_serie.map((serie) => (
                      <div
                        key={serie.id}
                        className={styles.FilterActiveSerie}
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/serie/${smartSlug(
                              serie.id,
                              serie.title.it
                            )}`
                          )
                        }
                      >
                        {' '}
                        {serie.title[i18n.language]}
                      </div>
                    ))}
                  </div>
                )}
                {clip.keywords_data.map((keyword) => (
                  <div
                    className={styles.FilterActive}
                    onClick={() =>
                      navigate(
                        `/${i18n.language}/archive/?keywords=${keyword.id}`
                      )
                    }
                    key={keyword.id}
                  >
                    {keyword.value[i18n.language]}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.DescriptionUp}>
                {clip.description[i18n.language]}
              </div>
              <div className="text-black mb-3">
                Share: <br />
                <FacebookShareButton url={shareUrl}>
                  <i className="bi bi-facebook text-black me-3"></i>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                  <i className="bi bi-twitter text-black me-3"></i>
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl}>
                  <i className="bi bi-whatsapp text-black me-3"></i>
                </WhatsappShareButton>
                <EmailShareButton url={shareUrl}>
                  <i className="bi bi-envelope text-black"></i>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
