import axios from 'axios'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { Content, PaginatedDJResponse } from '../types'
import { serializeQueryParams } from './utils'

export function useContents(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Content>>(
    ['contents', params],
    ({ signal }) =>
      axios
        .get(`/api/contents/`, {
          params: serializeQueryParams(params),
          signal,
        })
        .then((r) => r.data),
    {
      keepPreviousData: true,
    }
  )
}

export function getContent(idOrCode: number | string, signal?: AbortSignal) {
  return axios
    .get(`/api/contents/${idOrCode}/`, {
      signal,
    })
    .then((r) => r.data as Content)
}

export function useContent(idOrCode: number | string) {
  return useQuery(['content', idOrCode], ({ signal }) =>
    getContent(idOrCode, signal)
  ).data!
}

export function prefetchContent(
  client: QueryClient,
  idOrCode: number | string
) {
  return client.prefetchQuery(['content', idOrCode], ({ signal }) =>
    getContent(idOrCode, signal)
  )
}
