import styles from './Footer.module.css'
import credits1 from './assets/credits-1.png'
import credits2 from './assets/credits-2.png'
import credits3 from './assets/credits-3.png'
import credits4 from './assets/inmagik.svg'

export default function Footer() {
  return (
    <>
    <footer className={styles.Footer}>
      <div className="d-flex flex-column flex-md-row justify-content-between content-page">
        <div  className='d-flex flex-column align-items-start'>
          <div className="text-center font-size-14">Un progetto di</div>
          <a target={'_blank'} href="https://www.homemovies.it">
            <img className='mt-2' src={credits1} alt="Home Movies" />
          </a>
        </div>
        <div  className='d-flex mt-3 mt-md-0 flex-column align-items-start ms-0  ms-md-3'>
          <div className="text-center font-size-14">Con il contributo di</div>
          <img className='mt-3' src={credits2} alt="Home Movies" />
        </div>
        <div  className='d-flex mt-3 mt-md-0 flex-column align-items-start ms-0 ms-md-3'>
          <div className="text-center font-size-14">Partner</div>
          <img className='mt-4' src={credits3} alt="Home Movies" />
        </div>
        <div className='d-flex mt-3 mt-md-0 flex-column align-items-start ms-0 ms-md-3'>
          <div className="text-center mb-3 font-size-14">Web design</div>
          <a target={'_blank'} href="https://www.inmagik.com">
            <img src={credits4} height={50} width={50} alt="Home Movies" />
          </a>
        </div>
      </div>
    </footer>
    </>
  )
}
