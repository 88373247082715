import Layout from '../../components/Layout'
import styles from './About.module.css'
import Footer from '../../components/Footer'
import classNames from 'classnames'
import DividerGradient from '../../components/DividerGradient'
import background from './assets/about.jpeg'
import archivioImg from './assets/about-archivio.jpg'
import seriesImg from './assets/about-serie.jpg'
import viteImg from './assets/about-vite.jpg'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NavLangLink from '../../components/NavLangLink'
import { useTotals } from '../../hooks/clips'

export default function About() {
  const [info, setInfo] = useState(false)
  const [chiSiamo, setChiSiamo] = useState(false)
  const { t } = useTranslation()

  const data = useTotals()
  function scrollDown() {
    const height = window.innerHeight - 57
    window.scrollTo(0, height)
  }

  return (
    <Layout>
      <div
        className={styles.TopBg}
        style={{ backgroundImage: `url(${background})` }}
      >
        <h3 className={styles.TitleTop}>{t('title_about')}</h3>
        <div
          onClick={scrollDown}
          className={`container-icon ${styles.IconToBottom}`}
        >
          <i className="bi bi-arrow-down" />
        </div>
      </div>
      <DividerGradient />
      <div className="container-fluid">
        <div className="pt-2 row">
          <div className={classNames('offset-xl-2 col-xl-8 content-page-left')}>
            <div className="py-3 py-md-5">
              <div className={styles.TopParagraph}>{t('about_1')}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-2 mb-md-0">
            <NavLangLink to="/archive" className={'no-link pointer'}>
              <div
                className={styles.ImgBlock}
                style={{ backgroundImage: `url(${archivioImg})` }}
              >
                <div className={styles.Description}>{t('text_archivio')}</div>
                <div className={styles.InfoBlock}>
                  <div className={styles.TitleBlock}>
                    {t('archive_page_title')}
                  </div>

                  <div className={styles.NumBlock}>
                    {data.clips} <br /> clip
                  </div>
                </div>
              </div>
            </NavLangLink>
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <NavLangLink to="/serie" className={'no-link'}>
              <div
                className={styles.ImgBlock}
                style={{ backgroundImage: `url(${seriesImg})` }}
              >
                <div className={styles.Description}>{t('text_serie')}</div>
                <div className={styles.InfoBlock}>
                  <div className={styles.TitleBlock}>
                    {t('serie_page_title')}
                  </div>

                  <div className={styles.NumBlock}>
                    {data.serie} <br /> Serie
                  </div>
                </div>
              </div>
            </NavLangLink>
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <NavLangLink to="/vite-archivio" className={'no-link'}>
              <div
                className={styles.ImgBlock}
                style={{ backgroundImage: `url(${viteImg})` }}
              >
                <div className={styles.Description}>{t('text_vite')}</div>
                <div className={styles.InfoBlock}>
                  <div className={styles.TitleBlock}>
                    {t('vite_d_archivo_page_title')}
                  </div>

                  <div className={styles.NumBlock}>
                    {data.vite} <br /> Autori
                  </div>
                </div>
              </div>
            </NavLangLink>
          </div>
        </div>
        <div className="pt-2 row">
          <div className={classNames('offset-xl-2 col-xl-8 content-page-left')}>
            <div className="py-3 py-md-5">
              <div className={styles.MiddleParagraph}>
                <div>{t('about_2')}</div>
                <div className="py-2 pt-md-4">{t('about_3')}</div>
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex align-items-center">
                <div
                  className={classNames('container-icon pointer', {
                    'background-primary-100': chiSiamo,
                  })}
                  onClick={() => {
                    setInfo(false)
                    setChiSiamo(!chiSiamo)
                  }}
                >
                  <i
                    className={classNames('bi fs-4', {
                      'text-white bi-dash': chiSiamo,
                      'bi-plus': !chiSiamo,
                    })}
                  />
                </div>
                <div
                  className={classNames('ms-3', {
                    'text-primary-100': chiSiamo,
                  })}
                >
                  {t('chi_siamo')}
                </div>
              </div>
              {chiSiamo && (
                <div className={`${styles.TextAccordion} mt-3 mb-3`}>
                  <div>{t('about_4')}</div>
                  <div className="mt-3">{t('about_5')}</div>
                  <div className="mt-3">{t('about_6')}</div>
                  <div className='mt-3'>
                    {t('direzione_scientifica')}: Paolo Simoni
                    <br />
                    {t('progettezione_e_sviluppo')}: Giulia Simi <br />
                    {t('produzione')}: Vanessa Mangiavacca <br />
                    {t('selezione_testi')}: Davide Bianchi (Cartoline Italiane,
                    Lungo la Via Emilia, Paesaggi Urbani, Sport e Giochi, Vite
                    d’archivio: Enzo Pasi), Ilaria Ferretti (Franco Cigarini,
                    Scuola e Infanzia, Vite d’archivio: Oliviero Mario Olivo),
                    Chiara Petrucci (Vite d’archivio: Francesco Rinaldi), Silvia
                    Savorelli (Vite d’archivio: Claudio Benini, Sergio
                    Trombini). <br />
                    {t('editing')}: Davide Bianchi, Paolo Lancellotti, Michele
                    Manzolini, Tommaso Querin. <br />
                    {t('restauro')}: Giuseppe Fara, Mirco Santi. <br />
                    {t('collaborazione_alla_produzione')}: Giulia Dickmans,
                    Alessandro Conte, Guido Lingiardi. <br />
                    {t('sviluppo_piattaforma')}: Inmagik <br />
                    {t('identita_grafica')}: Irene Sgarro <br />
                    {t('ufficio_stampa')}: Luciana Apicella <br />
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center mt-3 mb-3">
                <div
                  className={classNames('container-icon pointer', {
                    'background-primary-100': info,
                  })}
                  onClick={() => {
                    setChiSiamo(false)
                    setInfo(!info)
                  }}
                >
                  <i
                    className={classNames('bi fs-4', {
                      'text-white bi-dash': info,
                      'bi-plus': !info,
                    })}
                  />
                </div>
                <div
                  className={classNames('ms-3', {
                    'text-primary-100': info,
                  })}
                >
                  {t('info')}
                </div>
              </div>
              {info && (
                <div className={`${styles.TextAccordion} mt-2 mb-3`}>
                  <div>
                    {t('info_text')}{' '}
                    <a
                      className="link-primary"
                      href="mailto:info@homemovies.it"
                    >
                      info@homemovies.it
                    </a>{' '}
                  </div>
                </div>
              )}
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </Layout>
  )
}
