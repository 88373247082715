import { ReactNode, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Layout from './Layout'

interface NavigationWrapperProps {
  className?: string
  children?: ReactNode
}

export default function NavigationWrapper({
  className = '',
  children,
}: NavigationWrapperProps) {
  return (
    <Suspense
      fallback={
        <Layout className={className}>
          <div className="d-flex container-spinner align-items-center justify-content-center">
            <div className='spinner' />
          </div>
        </Layout>
      }
    >
      {children ? children : <Outlet />}
    </Suspense>
  )
}
