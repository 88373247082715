import {
  QueryClient,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import { Clip, ClipDetail, ClipSuggest, PaginatedDJResponse, Total } from '../types'
import { serializeQueryParams, getNextPageParam } from './utils'

export type FacetRecord = Record<string, number>

export type AllFacets = Record<string, FacetRecord> & { count: number }

type MakeFacets<T extends readonly string[]> = Record<
  T[number],
  FacetRecord
> & { count: number }

export function getClipsFacets<T extends readonly string[]>(
  facets: T,
  params: Record<string, any>,
  signal?: AbortSignal
): Promise<MakeFacets<T>>

export async function getClipsFacets(
  facets: readonly string[],
  params: Record<string, any>,
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/facets/`, {
      signal,
      params: serializeQueryParams({ ...params, facets }),
    })
  ).data as AllFacets
}

export function useClipsFacets<T extends readonly string[]>(
  facets: T,
  params: Record<string, any> = {}
) {
  return useQuery(
    ['clipsFacets', { facets, params }],
    ({ signal }) => getClipsFacets<T>(facets, params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export function prefetchClipsFacets(
  client: QueryClient,
  facets: readonly string[],
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(
    ['clipsFacets', { facets, params }],
    ({ signal }) => getClipsFacets(facets, params, signal)
  )
}

export function usePrefetchClipsFacets(
  facets: readonly string[],
  params: Record<string, any> = {}
) {
  const queryClient = useQueryClient()
  prefetchClipsFacets(queryClient, facets, params)
}

export async function getClip(idOrSlug: string | number, signal?: AbortSignal) {
  return (await axios.get(`/api/clips/${idOrSlug}/`, { signal }))
    .data as ClipDetail
}

export function useClip(idOrSlug: string | number) {
  return useQuery(['clip', idOrSlug], ({ signal }) => getClip(idOrSlug, signal))
    .data!
}

export function prefetchDoc(client: QueryClient, idOrSlug: string | number) {
  return client.prefetchQuery(['clip', idOrSlug], ({ signal }) =>
    getClip(idOrSlug, signal)
  )
}

export async function getClips(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as PaginatedDJResponse<Clip>
}

export function useDocs(params: Record<string, any> = {}) {
  return useQuery(['docs', params], ({ signal }) => getClips(params, signal), {
    keepPreviousData: true,
  })
}

export function prefetchClips(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['clips', params], ({ signal }) =>
    getClips(params, signal)
  )
}

export function useInfiniteClips(params: Record<string, any> = {}) {
  return useInfiniteQuery(
    ['infiniteCLips', params],
    ({ signal, pageParam }) =>
      getClips(
        {
          ...params,
          ...pageParam,
        },
        signal
      ),
    {
      keepPreviousData: true,
      getNextPageParam,
    }
  )
}

export function prefetchInfiniteDocs(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchInfiniteQuery(['infiniteDocs', params], ({ signal }) =>
    getClips(params, signal)
  )
}

export async function getClipsSuggest(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips-suggest/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as ClipSuggest[]
}

export async function getPositions(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/positions/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as string[]
}

export function usePositions(params: Record<string, any> = {}) {
  return useQuery(
    ['positions', params],
    ({ signal }) => getPositions(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export async function getFormats(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/formats/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as string[]
}

export function useFormats(params: Record<string, any> = {}) {
  return useQuery(
    ['formats', params],
    ({ signal }) => getFormats(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export async function getTotals(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/clips/totals/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as Total
}

export function useTotals(params: Record<string, any> = {}) {
  return useQuery(
    ['totals', params],
    ({ signal }) => getTotals(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

