import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout'
import { useExtras } from '../../hooks/extra'
import styles from './Extra.module.css'
import DividerGradient from '../../components/DividerGradient'
import { Link } from 'react-router-dom'
import { smartSlug } from '../../utils/slug'
import { useContent } from '../../hooks/contents'

export default function Extra() {
  const { t, i18n } = useTranslation()
  const extras = useExtras()
  const territoriText = useContent('extra')
  return (
    <Layout>
      <div className="w-100">
        <DividerGradient />
        <div className={styles.ExtraBlock}>
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{t('extra_archive_page')}</h1>
              <p
                className={styles.Description}
                dangerouslySetInnerHTML={{
                  __html: territoriText.text[i18n.language],
                }}
              />
            </div>
          </div>
        </div>
        <DividerGradient />
      </div>
      <div className={`${styles.BlockExtras} container mt-5`}>
        <div className="row">
          {extras?.map((extra) => (
            <Link
              key={extra.id}
              to={`${smartSlug(extra.id, extra.title[i18n.language])}`}
              className="col-md-6 mb-3 no-link"
            >
              <div className="position-relative">
                <img
                  className={styles.Image}
                  src={extra.cover_image}
                  style={{
                    maxHeight: '300px',
                    objectFit: 'cover',
                  }}
                  alt={extra.title[i18n.language]}
                />
                {extra.type === 'evento' ? (
                  <div className={styles.TypeEvent}>{t('event')}</div>
                ) : extra.type === 'contenuto_speciale' ? (
                  <div className={styles.TypeSpecialContent}>
                    {t('special_content')}
                  </div>
                ) : (
                  <div className={styles.TypeApprofondimento}>
                    {t('approfondimento')}
                  </div>
                )}
                {extra.date_from && (
                  <div className={styles.PillDate}>
                    {dayjs(extra.date_from).format('DD/MM/YYYY')}
                  </div>
                )}
              </div>

              <div className={styles.Title}>{extra.title[i18n.language]}</div>
              <div className={styles.Description}>
                {extra.description_small[i18n.language]}
              </div>
              <hr />
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}
