export function createColGenerator(count: number) {
  let buffer = count
  return function getCol(size: number) {
    buffer -= size
    const adjustedSize = buffer < 0 ? buffer + size : size
    if (buffer <= 0) {
      buffer = count
    }
    const cols = (12 / count) * adjustedSize
    return cols
  }
}
