import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { PaginatedDJResponse, Serie } from '../types'
import { serializeQueryParams, getNextPageParam } from './utils'

export async function getSeries(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/series/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as PaginatedDJResponse<Serie>
}

export async function getSerie(idOrSlug: string | number, signal?: AbortSignal) {
  return (await axios.get(`/api/series/${idOrSlug}/`, { signal }))
    .data as Serie
}

export function useSerie(idOrSlug: string | number) {
  return useQuery(['serie', idOrSlug], ({ signal }) => getSerie(idOrSlug, signal))
    .data!
}

export function useHomeSerie() {
  return useQuery(['serie', 'home'], ({ signal }) => getSerie('home', signal))
    .data!
}

export function useInfiniteSeries(params: Record<string, any> = {}) {
  return useInfiniteQuery(
    ['infiniteSeries', params],
    ({ signal, pageParam }) =>
      getSeries(
        {
          ...params,
          ...pageParam,
        },
        signal
      ),
    {
      keepPreviousData: true,
      getNextPageParam,
    }
  )
}
