import Layout from '../../components/Layout'
import { usePrefetchSlides, useSlides } from '../../hooks/slides'
import 'swiper/css'
import 'swiper/css/pagination'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import styles from './Home.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import ClipPreview from '../../components/ClipPreview'
import { smartSlug } from '../../utils/slug'
import { useIsLateOnClient } from '../../LateOnClient'
import Footer from '../../components/Footer'
import { useHomeSerie } from '../../hooks/series'
import LangLink from '../../components/LangLink'
import BlocksHomeClips from '../../components/BlocksHomeClips'
import { useContent } from '../../hooks/contents'
import DividerGradient from '../../components/DividerGradient'

function getRandomArbitrary({ min, max = 100 }: { min: number; max: number }) {
  return Math.random() * (max - min) + min
}

export default function Home() {
  // NOTE: Reduce waterfall
  usePrefetchSlides()
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(10)
  const { i18n } = useTranslation()
  const homeSerie = useHomeSerie()
  const slides = useSlides()
  const playerRef = useRef<any>(null)
  const [currentVideo, setCurrentVideo] = useState(0)
  const { t } = useTranslation()

  const serieEvidenceTitle = useMemo(() => {
    if (homeSerie.title[i18n.language]) {
      return homeSerie.title[i18n.language]
    } else {
      return homeSerie.title.it
    }
  }, [i18n.language, homeSerie.title])

  useEffect(() => {
    const nextMin = Math.ceil(getRandomArbitrary({ min: 0, max: 25 }))
    const nextMax = nextMin + 10
    setMin(nextMin)
    setMax(nextMax)
  }, [currentVideo])

  const isLateOnClient = useIsLateOnClient()
  const homeText = useContent('home')
  const homeText2 = useContent('home-2')
  const [readMore, setReadMore] = useState(false)

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener('contextmenu', (e: any) => {
        e.preventDefault()
      })
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('contextmenu', (e: any) => {
          e.preventDefault()
        })
      }
    }
  }, [playerRef])

  return (
    <Layout className="bg-white">
      {slides && (
        <div>
          <video
            controlsList="nodownload"
            ref={playerRef}
            className={classNames(`${styles.Video}`, {})}
            src={`${slides[currentVideo].clip_data.file_url_vimeo}#t=${min},${max}`}
            width={'100%'}
            muted
            controls={false}
            autoPlay
            loop
            onPause={() => {
              if (currentVideo === slides.length - 1) {
                setCurrentVideo(0)
              } else {
                setCurrentVideo(currentVideo + 1)
              }
            }}
          ></video>
          <div className={styles.TextSlide}>
            <h3>
              {slides[currentVideo].title[i18n.language]
                ? slides[currentVideo].title[i18n.language]
                : slides[currentVideo].title.it}
            </h3>
            <div className="ms-0 ms-md-5">
              {slides[currentVideo].link && (
                <Button
                  as={Link as any}
                  to={slides[currentVideo].link}
                  variant="light"
                  className={styles.ButtonClip}
                >
                  {t('vai_alla_clip')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={'overflow-hidden pt-5 pb-5 bg-white'}>
        <div className="row">
          <div className="offset-xl-2 col-xl-8 pt-3">
            <div className="content-page">
              <div
                className="text-home"
                dangerouslySetInnerHTML={{
                  __html: homeText.text[i18n.language],
                }}
              ></div>
              <div className="d-flex align-items-center">
                <div
                  className={classNames('container-icon pointer', {
                    'background-primary-100': readMore,
                  })}
                  onClick={() => setReadMore(!readMore)}
                >
                  <i
                    className={classNames('bi fs-4', {
                      'text-white bi-dash': readMore,
                      'bi-plus': !readMore,
                    })}
                  />
                </div>
                <div
                  className={classNames('ms-3', {
                    'text-primary-100': readMore,
                  })}
                >
                  {!readMore ? t('read_more') : t('read_less')}
                </div>
              </div>
              {readMore && homeText2.code && (
                <div
                  className={styles.TextReadMore}
                  dangerouslySetInnerHTML={{
                    __html: homeText2.text[i18n.language],
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DividerGradient />
      <div className={'content-page bg-white pt-5 pb-4'}>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-start">
              <LangLink
                className="no-link"
                to={`/serie/${smartSlug(
                  homeSerie.id,
                  homeSerie.title[i18n.language] || homeSerie.title.it || ''
                )}`}
              >
                <h4 className={styles.TitleSection}>{serieEvidenceTitle}</h4>
              </LangLink>
              <div className={styles.NumClip}>
                {homeSerie.clips_count} <br />
                clips
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.DescriptionSerie}>
              {homeSerie.description_home[i18n.language] ||
                homeSerie.description_home.it ||
                homeSerie.description[i18n.language] ||
                homeSerie.description.it ||
                ''}
            </div>
            <div className="mt-3">
              <Button
                variant="light"
                className={styles.Button}
                as={Link as any}
                to={`/${i18n.language}/serie/${smartSlug(
                  homeSerie.id,
                  homeSerie.title[i18n.language] || homeSerie.title.it || ''
                )}`}
              >
                {t('vai_alla_serie')}
              </Button>
            </div>
          </div>
        </div>

        <div className="row mt-5 pb-5">
          {homeSerie.clips.map((clip, i) => (
            <ClipPreview clip={clip} key={i} />
          ))}
        </div>
      </div>
      <div className="content-page pt-5 bg-violet-opacity">
        {isLateOnClient && (
          <Suspense fallback={<div className="spinner" />}>
            <BlocksHomeClips />
          </Suspense>
        )}
      </div>
      <div className="content-page bg-white pt-5 pb-5">
        <Link className='no-link' to={`/${i18n.language}/donate`}>
        <div className="banner-5x1000">
          <div>
            <h4>{t('come_eravamo_come_saremo')}</h4>
            <div>{t('text_5_x_1000')}</div>
            <div>{t('text_2_5_x_1000')}</div>
            <div>CF. 91226000379</div>
          </div>
          <div>
            <LangLink
              to="/donate"
              lang={i18n.language}
              className="button-donate"
            >
              {t('donate_now')}
            </LangLink>
          </div>
        </div>
        </Link>
      </div>
      <Footer />
    </Layout>
  )
}
