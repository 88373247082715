import classNames from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Clip } from '../../types'
import { createColGenerator } from '../../utils/col'
import { smartSlug } from '../../utils/slug'
import LangLink from '../LangLink'
import styles from './ClipPreview.module.css'

function isDocImageHoriz(clip: Clip) {
  if (!clip.content_metadata.width || !clip.content_metadata.height) {
    return false
  }
  return clip.content_metadata.width! > clip.content_metadata.height!
}

interface ClipPreviewProps {
  clip: Clip
  classCol?: boolean
  showInfo?: boolean
  showYear?: boolean
  marginRight?: boolean
  hoverWhite?: boolean
  showHover?: boolean
}

export default function ClipPreview({
  clip,
  classCol = true,
  showInfo = true,
  showYear = true,
  showHover = true,
  marginRight = false,
  hoverWhite = false
}: ClipPreviewProps) {
  const { i18n } = useTranslation()
  const getDesktopCol = createColGenerator(8)
  const getTabletCol = createColGenerator(4)
  const getMobileCol = createColGenerator(2)
  const size = isDocImageHoriz(clip) ? 2 : 1
  // Dekstop
  const desktopClass = `col-xl-${getDesktopCol(size)}`
  // Mobile
  const mobileClass = `col-${getMobileCol(size)}`

  const tabletClass = `col-md-${getTabletCol(size)}`

  const title = useMemo(() => {
    return clip.title[i18n.language] ? clip.title[i18n.language] : clip.title.it
  }, [clip.title, i18n.language])

  return (
    <div
      key={clip.id}
      className={classNames('mb-3',{
        [desktopClass]: classCol,
        [tabletClass]: classCol,
        [mobileClass]: classCol,
        [styles.marginRight]: marginRight
      })}
    >
      <LangLink
        className="no-link h-100"
        to={`/clips/${smartSlug(clip.id, title)}`}
        key={clip.id}
      >
        <div className={classNames(`pb-3`, {
          [styles.ContainerItem]: !hoverWhite && showHover,
          [styles.ContainerItemWhite]: hoverWhite && showHover,
        })}>
          <div
            style={{
              backgroundImage: `url(${clip.image_medium})`,
            }}
            className={`${styles.Item}`}
          >
            {clip.date && showYear && (
              <div className={styles.Date}>
                {dayjs(clip.date).format('YYYY')}
              </div>
            )}
          </div>
          <div>
            {showInfo && (
              <div className={styles.InfoItem}>
                <h5 className={styles.Title}>{title}</h5>
                <div className={styles.Posizione}>{clip.city}</div>
              </div>
            )}
          </div>
        </div>
      </LangLink>
    </div>
  )
}
