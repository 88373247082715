import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { useClip } from '../../hooks/clips'
import { parseSmartSlug, smartSlug } from '../../utils/slug'
import styles from './ClipDetail.module.css'
import FullClip from '../../components/FullClip'
import ClipPreview from '../../components/ClipPreview'
import LangLink from '../../components/LangLink'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import { useEffect, useState } from 'react'
import { useIsMobileScreen } from '../../hooks/screen'
import DividerGradient from '../../components/DividerGradient'

export default function ClipDetail() {
  const { i18n, t } = useTranslation()
  const { slug } = useParams()
  const clip = useClip(parseSmartSlug(slug!))
  const navigate = useNavigate()

  const [showTop, setShowTop] = useState<boolean>(true)

  const isMobile = useIsMobileScreen()

  useEffect(() => {
    const onScroll = () => {
      if (!isMobile) {
        if (window.pageYOffset < 50) {
          setShowTop(true)
        } else {
          setShowTop(false)
        }
      }
    }

    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [isMobile])

  return (
    <Layout className="h-100 w-100">
      <Helmet>
        <title>Memoryscapes - {`${clip.title['it']}`}</title>
        <meta name="description" content={`${clip.description['it']}`} />
        <meta
          property="og:title"
          content={`Memoryscapes - ${clip.title['it']}`}
        />
        <meta property="og:description" content={`${clip.description['it']}`} />
        <meta property="og:image" content={`${clip.image_preview}`} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Memoryscapes - ${clip.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${clip.description['it']}`}
        />
        <meta name="twitter:image" content={`${clip.image_preview}`} />
      </Helmet>
      <FullClip showTop={showTop} key={clip.id} clip={clip} />
      <div className="content-page mt-5 pb-0 pb-md-5">
        <div className={`row d-none d-xl-flex`}>
          <div className="col-md-12 mb-4">
            <h2 className={styles.TitleDown}>{clip.title[i18n.language]}</h2>
          </div>
          <div className="col-md-6">
            <div className={styles.InfoClipDown}>
              <div className="d-flex">
                <div className={styles.LabelInfo}>{t('autore')}</div>
                <LangLink
                  className="link-filters"
                  to={`/archive/?authors=${clip.author_data?.name}`}
                >
                  {clip.author ? clip.author_data?.name : ''}
                </LangLink>
              </div>
              <div className="d-flex">
                <div className={styles.LabelInfo}>{t('tipologia')}</div>
                <LangLink
                  className="link-filters"
                  to={`/archive/?formats=${clip.format}`}
                >
                  {clip.format}
                </LangLink>{' '}
                - {clip.archive}
              </div>
              {clip.city && (
                <div className="d-flex">
                  <div className={styles.LabelInfo}>{t('luogo')}</div>
                  <LangLink
                    className="link-filters"
                    to={`/archive/?positions=${clip.city}`}
                  >
                    {' '}
                    {clip.city}
                  </LangLink>{' '}
                </div>
              )}
              {clip.date && (
                <div className="d-flex">
                  <div className={styles.LabelInfo}>{t('anno')}</div>
                  <LangLink
                    className="link-filters"
                    to={`/archive/?from_date=${dayjs(clip.date).format(
                      'YYYY'
                    )}-01-01&to_date=${dayjs(clip.date).format('YYYY')}-12-31`}
                  >
                    {dayjs(clip.date).format('YYYY')}
                  </LangLink>
                </div>
              )}
            </div>
            <div className="d-flex mt-2">
              {clip?.related_clips_serie.length > 0 && (
                <div className="d-flex">
                  {clip.related_clips_serie.map((serie) => (
                    <div
                      key={serie.id}
                      className={styles.FilterActiveSerie}
                      onClick={() =>
                        navigate(
                          `/${i18n.language}/serie/${smartSlug(
                            serie.id,
                            serie.title.it
                          )}`
                        )
                      }
                    >
                      {' '}
                      {serie.title[i18n.language]}
                    </div>
                  ))}
                </div>
              )}
              <div className="d-flex">
                {clip.keywords_data.map((keyword) => (
                  <div
                    onClick={() =>
                      navigate(
                        `/${i18n.language}/archive/?keywords=${keyword.id}`
                      )
                    }
                    className={styles.FilterActive}
                    key={keyword.id}
                  >
                    {keyword.value[i18n.language]}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className={styles.DescriptionDown}>
              {clip.description[i18n.language]}
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-xl-block">
        <DividerGradient />
      </div>
      <div className="content-page">
        {clip.author && clip?.related_clips_author.length > 0 && (
          <div className="row">
            <div className="mb-5">
              <h3 className={styles.Correlati}>
                {t('clip_correlate_di')} {clip.author_data?.name}
              </h3>
            </div>
            {clip?.related_clips_author.map((clip, i) => (
              <ClipPreview clip={clip} key={i} />
            ))}
          </div>
        )}
        <div className="row mt-2">
          <div className="mb-5">
            <h3 className={styles.Correlati}>{t('clip_correlate_keywords')}</h3>
          </div>
          {clip?.related_clips_keywords.map((clip, i) => (
            <ClipPreview clip={clip} key={i} />
          ))}
        </div>
        {clip.date && clip?.related_clips_year.length > 0 && (
          <div className="row mt-2">
            <div className="mb-3">
              <h3 className={styles.Correlati}>
                {t('clip_correlate_anno')} {dayjs(clip.date).format('YYYY')}
              </h3>
            </div>
            {clip?.related_clips_year.map((clip, i) => (
              <ClipPreview clip={clip} key={i} />
            ))}
          </div>
        )}
        {clip.city && clip?.related_clips_city.length > 0 && (
          <div className="row mt-2">
            <div className="mb-5">
              <h3 className={styles.Correlati}>
                {t('clip_correlate_per')} {clip.city}
              </h3>
            </div>
            {clip?.related_clips_city.map((clip, i) => (
              <ClipPreview clip={clip} key={i} />
            ))}
          </div>
        )}
        {clip?.related_clips_serie.length > 0 && (
          <>
            {clip?.related_clips_serie.map((serie) => (
              <div className="row mt-2">
                <div className="mb-5">
                  <h3 className={styles.Correlati}>
                    {t('clip_della_serie')} {serie.title[i18n.language]}
                  </h3>
                </div>
                {serie.clips.map((clip, i) => (
                  <ClipPreview clip={clip} key={i} />
                ))}
              </div>
            ))}
          </>
        )}
      </div>
      <Footer />
    </Layout>
  )
}
