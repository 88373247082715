import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { parseSmartSlug, smartSlug } from '../../utils/slug'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import { useState } from 'react'
import { useExtra } from '../../hooks/extra'
import DividerGradient from '../../components/DividerGradient'
import dayjs from 'dayjs'
import ClipPreview from '../../components/ClipPreview'
import styles from './ExtraDetail.module.css'
import { SwiperClips } from '../ViteArchivioDetail/ViteArchivioDetail'

function BlockImage({ block }: { block: any }) {
  return (
    <div className="offset-md-3 col-md-6 mb-3 no-link">
      <div className="position-relative">
        <img className="img-fluid" src={block.image} alt={block.title} />
      </div>
    </div>
  )
}

function BlockText({ block }: { block: any }) {
  const { i18n } = useTranslation()
  return (
    <div className="offset-md-2 col-md-8 mb-3 text-center mt-4">
      <div
        className="text-center"
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: block.text[i18n.language],
        }}
      />
    </div>
  )
}

function BlockVideo({ block }: { block: any }) {
  return (
    <div className="offset-md-3 col-md-6 mb-3 no-link">
      <iframe
        className="w-100"
        height="315"
        src={`https://player.vimeo.com/video/${
          block.video.split('/')[block.video.split('/').length - 1]
        }`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

function BlockClipDaArchivio({ block }: { block: any }) {
  const { i18n } = useTranslation()
  return (
    <div className="offset-md-3 col-md-6 mb-3">
      <Link
        className="no-link"
        to={`/${i18n.language}/clips/${smartSlug(
          block.clip.id,
          block.clip.title[i18n.language]
        )}`}
      >
        <div className={`${styles.ContainerItem} pb-3`}>
          <img
            className="img-fluid"
            src={block.clip.image_preview}
            alt={block.clip.title[i18n.language]}
          />
          <div className={styles.InfoItem}>
            <h5 className={styles.Title}>{block.clip.title[i18n.language]}</h5>
            <div className={styles.Posizione}>{block.clip.city}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

function BlockClips({ block }: { block: any }) {
  return (
    <div className="mt-4 mb-4">
      <SwiperClips clips={block.clips} />
    </div>
  )
}

export default function ExtraDetail() {
  const { i18n, t } = useTranslation()
  const { slug } = useParams()
  const extra = useExtra(parseSmartSlug(slug!))

  return (
    <Layout className="h-100 w-100">
      <Helmet>
        <title>Memoryscapes - {`${extra.title['it']}`}</title>
        <meta name="description" content={`${extra.description_small['it']}`} />
        <meta
          property="og:title"
          content={`Memoryscapes - ${extra.title['it']}`}
        />
        <meta
          property="og:description"
          content={`${extra.description_small['it']}`}
        />
        <meta property="og:image" content={`${extra.cover_image}`} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Memoryscapes - ${extra.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${extra.description_small['it']}`}
        />
        <meta name="twitter:image" content={`${extra.cover_image}`} />
      </Helmet>
      <DividerGradient />
      <div className="mt-2 mb-5 container">
        <div className="row">
          <div className="offset-md-2 col-md-8 text-center">
            <h1 className="my-4">{extra.title[i18n.language]}</h1>
            <p className="text-center text-serif" style={{ whiteSpace: 'pre-line' }}>
              {extra.description_small[i18n.language]}
            </p>
          </div>
          <div className="offset-md-2 col-md-8">
            {extra.type === 'evento' && (
              <div className="mt-2 d-flex justify-content-center">
                <div className="mb-2 me-3">
                  <strong>{dayjs(extra.date_from).format('DD/MM/YYYY')}</strong>
                </div>
                <div className="mb-2 me-3">-</div>
                <div className="mb-2 me-3">
                  <strong>{extra.hour}</strong>
                </div>
                <div className="mb-2 me-3">-</div>
                <div>{extra.place}</div>
              </div>
            )}
          </div>
          <div className="offset-md-3 col-md-6 mt-4">
            <img
              className="w-100"
              src={extra.cover_image}
              alt={extra.title[i18n.language]}
            />
          </div>
          <div className="offset-md-2 col-md-8 mt-4">
            <hr />
          </div>
          {extra.blocks_content.map((block: any, index: number) =>
            block.type === 'image' ? (
              <BlockImage key={index} block={block} />
            ) : block.type === 'text' ? (
              <BlockText key={index} block={block} />
            ) : block.type === 'video' ? (
              <BlockVideo key={index} block={block} />
            ) : block.type === 'clip_from_archive' ? (
              <BlockClipDaArchivio key={index} block={block} />
            ) : block.type === 'clips_list' ? (
              <BlockClips key={index} block={block} />
            ) : (
              <div key={index} />
            )
          )}
        </div>
      </div>

      <Footer />
    </Layout>
  )
}
