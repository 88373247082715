import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { Extra } from '../types'
import { serializeQueryParams } from './utils'

export async function getExtras(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/extras/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as Extra[]
}

export function useExtras(params: Record<string, any> = {}) {
  return useQuery(
    ['extras', params],
    ({ signal }) => getExtras(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export async function getExtra(id: number | string, signal?: AbortSignal) {
  return (
    await axios.get(`/api/extras/${id}/`, {
      signal,
    })
  ).data as Extra
}

export function useExtra(id: number | string) {
  return useQuery(['extra', id], ({ signal }) => getExtra(id, signal)).data!
}
