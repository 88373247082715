import {
  createContext,
  ReactNode,
  startTransition,
  useContext,
  useEffect,
  useState,
} from 'react'

const LateOnClientContext = createContext(false)

export function useIsLateOnClient() {
  return useContext(LateOnClientContext)
}

export function LateOnClientProvider({ children }: { children: ReactNode }) {
  const [isLateOnClient, setIsLateOnClient] = useState(false)
  useEffect(() => {
    startTransition(() => {
      setIsLateOnClient(true)
    })
  }, [])
  return (
    <LateOnClientContext.Provider value={isLateOnClient}>
      {children}
    </LateOnClientContext.Provider>
  )
}
