import DividerGradient from '../components/DividerGradient'
import Layout from '../components/Layout'

export default function CookieConsent() {
  return (
    <Layout>
      <div>
        <div className="w-100">
          <DividerGradient />
          <div className="d-flex d-mb-block align-items-center w-100 justify-content-between p-container-top">
            <h2 className="title-page-with-padding d-flex align-items-center">
              Cookie policy
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row ms-2 me-2 mt-4">
          <div className='col-md-12'>
            <div>
              Informativa resa ai sensi dell’art.13 del D. L. vo 30 giugno 2003
              n. 196 “Codice in materia di protezione dei dati personali”.{' '}
              <br />
            </div>
            <br />
            <br />
            <div>Il sito Memoryscapes fa uso di 2 tipologie di cookie:</div>
            <ul className="ps-5 mt-3">
              <li>
                Cookie tecnici: Memoryscapes non fa uso di cookie per la
                trasmissione di informazioni di carattere personale, ovvero
                sistemi per il tracciamento degli utenti. I cookie tecnici sono
                indispensabili per il corretto funzionamento del sito. Il
                contenuto dei cookie è strettamente limitato alla trasmissione
                di identificativi di sessione (costituiti da stringhe casuali
                generati dal server) necessari per consentire l'esplorazione
                sicura ed efficiente del sito. La durata dei cookie è
                strettamente limitata alla sessione di lavoro. I c.d. cookie di
                sessione utilizzati in questo sito evitano il ricorso ad altre
                tecniche informatiche potenzialmente pregiudizievoli per la
                riservatezza della navigazione degli utenti e non consentono
                l'acquisizione di dati personali identificativi dell'utente.
              </li>
              <li>
                Cookie di terze parti: Visitando il sito web Memoryscapes si
                possono ricevere cookie Google Analytics. Tali cookie hanno
                finalità esclusivamente di tipo statistico nella navigazione del
                sito Memoryscapes, mediante Google Analytics e sono anonimizzati
                in modo da non rendere riconoscibile l'utente che effettua la
                navigazione Per maggiori informazioni sulle modalità di lavoro
                del cookie Google Analytics si invita a leggere l'informativa di
                dettaglio:
                http://www.google.com/intl/it_ALL/analytics/learn/privacy.html
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
