import { Nav, Offcanvas } from 'react-bootstrap'
import { LANGS } from '../../i18n'
import { useIsMobileMenuOpen, useMobileMenuToggler } from '../../MobileMenu'
import ChangeLangLink from '../ChangeLangLink'
import NavLangLink from '../NavLangLink'
import styles from './OffcanvasMobileMenu.module.css'
import logo from '../../assets/logo.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg'

export default function OffcanvasMobileMenu() {
  const { t } = useTranslation()
  const open = useIsMobileMenuOpen()
  const toggle = useMobileMenuToggler()

  return (
    <Offcanvas placement="start" show={open} onHide={toggle}>
      <div className="navbar-nav">
        <Offcanvas.Body className={styles.CanvasBody}>
          <NavLangLink
            onClick={toggle}
            end
            className="pt-5 mt-2 navbar-memory-name"
            to="/"
          >
            Memoryscapes
          </NavLangLink>
          <div onClick={toggle} className={styles.CloseCanvas}>
            <i className="bi fs-2 bi-x-lg" />
          </div>
          <Nav className="me-auto mt-2 navbar-nav">
            <NavLangLink
              end
              className="nav-link me-3"
              to="/archive"
              onClick={toggle}
            >
              {t('archive_page_title')}
            </NavLangLink>
            <NavLangLink
              end
              className="nav-link me-3"
              to="/serie"
              onClick={toggle}
            >
              {t('serie_page_title')}
            </NavLangLink>
            <NavLangLink
              end
              className="nav-link me-3"
              to="/vite-archivio"
              onClick={toggle}
            >
              {t('vite_d_archivo_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/territories" onClick={toggle}>
              {t('territories_page_title')}
            </NavLangLink>
            <NavLangLink end className="nav-link me-3" to="/extra" onClick={toggle}>
              {t('extra_archive_page')}
            </NavLangLink>
            <NavLangLink
              end
              className="nav-link me-3"
              to="/project"
              onClick={toggle}
            >
              {t('chi_siamo_archive_page')}
            </NavLangLink>
            <NavLangLink
              end
              onClick={toggle}
              className="nav-link me-3"
              to="/donate"
            >
              {t('dona_title_page')}
            </NavLangLink>
          </Nav>
          <hr />
          <div className="d-flex">
            {LANGS.map((lang) => (
              <ChangeLangLink
                key={lang}
                className="text-capitalize nav-link me-3"
                lang={lang}
                activeLangClassName="text-underline-primary"
              >
                {lang}
              </ChangeLangLink>
            ))}
          </div>
          <hr />
          <div className="d-flex">
            <Nav.Link
              href="https://www.facebook.com/archiviohomemovies/"
              target={'_blank'}
              rel="noopener"
              className="me-3"
            >
              <FacebookIcon />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/archiviohomemovies/"
              target={'_blank'}
              rel="noopener"
            >
              <InstagramIcon />
            </Nav.Link>
          </div>
        </Offcanvas.Body>
      </div>
    </Offcanvas>
  )
}
