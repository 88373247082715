import {
  ComponentType,
  Fragment,
  lazy,
  useEffect,
  Suspense,
  useState,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import dayjs from 'dayjs'
import Layout from '../../components/Layout'
import {
  useClipsFacets,
  useFormats,
  useInfiniteClips,
  usePositions,
  usePrefetchClipsFacets,
} from '../../hooks/clips'
import { useSerie } from '../../hooks/series'
import styles from '../SerieDetail/SerieDetail.module.css'
import { useQsFilters } from '../filters'
import ClipFiltersCanvas, {
  ClipFiltersCanvasProps,
} from '../../components/ClipFiltersCanvas'
import { useKeywords, usePrefetchKeywords } from '../../hooks/keywords'
import { useAuthors } from '../../hooks/authors'
import ClipPreview from '../../components/ClipPreview'
import { parseSmartSlug } from '../../utils/slug'
import { Keyword } from '../../types'
import { useIsLateOnClient } from '../../LateOnClient'
import { Helmet } from 'react-helmet-async'
import noResults from '../../assets/no-results.svg'
import DividerGradient from '../../components/DividerGradient'
import { useTerritorio } from '../../hooks/territories'
import classNames from 'classnames'

const getFilters = (params: URLSearchParams) => ({
  search: params.get('search') ?? '',
  page_size: params.get('page_size') ?? 100,
  keywords: params.getAll('keywords') ?? [],
  authors: params.getAll('authors') ?? [],
  from_date: params.get('from_date') ?? '',
  to_date: params.get('to_date') ?? '',
  view: params.get('view') ?? 'list',
  positions: params.getAll('positions') ?? [],
  formats: params.getAll('formats') ?? [],
  order: params.get('order') ?? '-id',
})

function WrapClipFiltersCanvas(
  props: Omit<ClipFiltersCanvasProps, 'authors' | 'positions' | 'formats'>
) {
  const { slug } = useParams()
  const serieID = parseSmartSlug(slug!)
  const authors = useAuthors({ serie: serieID })
  const positions = usePositions({ serie: serieID })
  const formats = useFormats({ serie: serieID })
  return (
    <ClipFiltersCanvas
      positions={positions}
      formats={formats}
      authors={authors}
      {...props}
    />
  )
}

function LoadingMap() {
  return (
    <div className="text-center">
      <div>Loading Map...</div>
      <br />
      <div className="spinner" />
    </div>
  )
}

export default function TerritoryDetail() {
  const { slug } = useParams()
  const { filters, setFilters } = useQsFilters(getFilters)
  const { i18n, t } = useTranslation()
  const territorioId = parseSmartSlug(slug!)
  const territorio = useTerritorio(territorioId)

  const [showCanvas, setShowCanvas] = useState(false)

  const keywords = useKeywords({ territorio: territorioId })
  const keywordsById = useMemo(
    () =>
      keywords.reduce((o, k) => {
        o[String(k.id)] = k
        return o
      }, {} as Record<string, Keyword>),
    [keywords]
  )

  const {
    data: infinitClips,
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteClips({
    ...filters,
    territorio: territorioId,
    lang: i18n.language,
  })

  const facets = useClipsFacets(['date__year'] as const, {
    serie: parseSmartSlug(slug!),
  })

  const handleCloseCanvas = () => setShowCanvas(false)
  const handleShowCanvas = () => setShowCanvas(true)

  const [MapComponent, setMapComponent] = useState<ComponentType<any>>(
    () => LoadingMap
  )

  useEffect(() => {
    if (!import.meta.env.SSR) {
      setMapComponent(() => lazy(() => import('./MapTerritory')))
    }
  }, [])

  const isLateOnClient = useIsLateOnClient()

  const [showMore, setShowMore] = useState(false)
  const [showCredits, setShowCredits] = useState(false)

  usePrefetchKeywords({ territorio: territorioId })
  usePrefetchClipsFacets(['date__year'] as const, { territorio: territorioId })

  return (
    <Layout
      right={
        <div className="pointer" onClick={handleShowCanvas}>
          <i className="bi fs-5 bi-search" />
        </div>
      }
    >
      <Helmet>
        <title>Memoryscapes - {`${territorio.title['it']}`}</title>
        <meta name="description" content={`${territorio.description['it']}`} />
        <meta property="og:title" content={`${territorio.title['it']}`} />
        <meta
          property="og:description"
          content={`${territorio.description['it']}`}
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Memoryscapes - ${territorio.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${territorio.description['it']}`}
        />
      </Helmet>
      <div className="w-100">
        <div className={styles.BlockTitle}>
          <div className="w-100">
            <DividerGradient />
            <div className="d-flex d-mb-block align-items-center w-100 justify-content-between p-container-top">
              <h2 className="title-page-with-padding d-flex align-items-center">
                {territorio.title[i18n.language]
                  ? territorio.title[i18n.language]
                  : territorio.title.it}
                <div className={styles.NumClips}>
                  {infinitClips?.pages[0].count !==
                  infinitClips?.pages[0].total_count
                    ? infinitClips?.pages[0].count
                    : infinitClips?.pages[0].total_count}{' '}
                  <br />
                  clip
                </div>
                {isFetching && <div className="spinner ms-3"></div>}
              </h2>
              <div className={styles.BlockInfoTop}>
                <div
                  onClick={() => {
                    setFilters({ ...filters, page_size: 100, view: 'list' })
                  }}
                  className={
                    filters.view === 'list'
                      ? styles.LabelTopSelected
                      : styles.LabelTop
                  }
                >
                  {t('lista')}
                </div>
                <div
                  onClick={() => {
                    setFilters({ ...filters, page_size: 5000, view: 'map' })
                  }}
                  className={
                    filters.view === 'map'
                      ? styles.LabelTopSelected
                      : styles.LabelTop
                  }
                >
                  {t('mappa')}
                </div>
                <div
                  className="ms-3  d-none d-xl-flex pointer container-icon"
                  onClick={handleShowCanvas}
                >
                  <i className="bi bi-search" />
                </div>
              </div>
            </div>
            <div className="content-page">
              <div className="d-flex flex-column flex-md-row align-items-md-center">
                <div className="ms-0 d-flex flex-md-wrap filters-active">
                  {filters.authors.length > 0 &&
                    filters.authors.map((author) => (
                      <div
                        key={author}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            authors: filters.authors
                              .filter((a) => a !== author)
                              .map((a) => a),
                          })
                        }}
                        className={styles.FilterActive}
                      >
                        {author}
                        <i className="bi bi-x ms-1" />
                      </div>
                    ))}
                  {filters.keywords.length > 0 &&
                    filters.keywords.map((keyword) =>
                      keywordsById[keyword] ? (
                        <div
                          key={keywordsById[keyword].id}
                          onClick={() =>
                            setFilters({
                              ...filters,
                              keywords: filters.keywords
                                .filter((f) => f !== keyword)
                                .map((f) => f),
                            })
                          }
                          className={styles.FilterActive}
                        >
                          {keywordsById[keyword].value?.[i18n.language]}
                          <i className="bi bi-x ms-1" />
                        </div>
                      ) : null
                    )}
                  {filters.formats.length > 0 &&
                    filters.formats.map((format) => (
                      <div
                        key={format}
                        onClick={() =>
                          setFilters({
                            ...filters,
                            formats: filters.formats
                              .filter((f) => f !== format)
                              .map((f) => f),
                          })
                        }
                        className={styles.FilterActive}
                      >
                        {format}
                        <i className="bi bi-x ms-1" />
                      </div>
                    ))}
                  {filters.positions.length > 0 &&
                    filters.positions.map((position) => (
                      <div
                        key={position}
                        onClick={() =>
                          setFilters({
                            ...filters,
                            positions: filters.positions
                              .filter((p) => p !== position)
                              .map((p) => p),
                          })
                        }
                        className={styles.FilterActive}
                      >
                        {position}
                        <i className="bi bi-x ms-1" />
                      </div>
                    ))}
                  {filters.search !== '' && (
                    <div
                      onClick={() =>
                        setFilters({
                          ...filters,
                          search: '',
                        })
                      }
                      className={styles.FilterActive}
                    >
                      {filters.search}
                      <i className="bi bi-x ms-1" />
                    </div>
                  )}
                  {(filters.from_date !== '' || filters.to_date !== '') && (
                    <div
                      onClick={() =>
                        setFilters({
                          ...filters,
                          to_date: '',
                          from_date: '',
                        })
                      }
                      className={styles.FilterActive}
                    >
                      {dayjs(filters.from_date).format('YYYY')} -{' '}
                      {dayjs(filters.to_date).format('YYYY')}
                      <i className="bi bi-x ms-1" />
                    </div>
                  )}
                  {infinitClips?.pages[0].count !== territorio.clips_count && (
                    <div
                      onClick={() =>
                        setFilters({
                          authors: [],
                          formats: [],
                          keywords: [],
                          positions: [],
                          search: '',
                          to_date: '',
                          from_date: '',
                        })
                      }
                      className={styles.RemoveAll}
                    >
                      {t('remove_all_filters')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          filters.view === 'list' || filters.view === 'credits'
            ? styles.BlockSerie
            : styles.BlockSerieMap
        }`}
      >
        {(filters.view === 'list' || filters.view === 'credits') && (
          <div className="row mb-3">
            <div className="col-md-12">
              <div className={styles.Description}>
                {territorio.description[i18n.language]
                  ? territorio.description[i18n.language]
                  : territorio.description.it}
              </div>
            </div>
            <div className="col-md-12 mt-2">
              {territorio.extra_text[i18n.language] && (
                <div className="d-flex align-items-center mb-3">
                  <div
                    className={classNames('container-icon pointer', {
                      'background-primary-100': showMore,
                    })}
                    onClick={() => {
                      setShowMore(!showMore)
                    }}
                  >
                    <i
                      className={classNames('bi fs-4', {
                        'text-white bi-dash': showMore,
                        'bi-plus': !showMore,
                      })}
                    />
                  </div>
                  <div
                    className={classNames('ms-3', {
                      'text-primary-100': showMore,
                    })}
                  >
                    {t('read_more')}
                  </div>
                </div>
              )}
              {showMore && (
                <div
                  className={styles.TextReadMore}
                  dangerouslySetInnerHTML={{
                    __html: territorio.extra_text[i18n.language],
                  }}
                ></div>
              )}
            </div>
            <div className="col-md-12">
              {territorio.credits[i18n.language] && (
                <div className="d-flex align-items-center mb-3">
                  <div
                    className={classNames('container-icon pointer', {
                      'background-primary-100': showCredits,
                    })}
                    onClick={() => {
                      setShowCredits(!showCredits)
                    }}
                  >
                    <i
                      className={classNames('bi fs-4', {
                        'text-white bi-dash': showCredits,
                        'bi-plus': !showCredits,
                      })}
                    />
                  </div>
                  <div
                    className={classNames('ms-3', {
                      'text-primary-100': showCredits,
                    })}
                  >
                    {t('credits')}
                  </div>
                </div>
              )}
              </div>
              {showCredits && (
                <div
                  className={styles.TextReadMore}
                  dangerouslySetInnerHTML={{
                    __html: territorio.credits[i18n.language],
                  }}
                ></div>
              )}
          </div>
        )}
        {filters.view === 'list' && (
          <>
            <div className="row mt-2 mt-md-5">
              {infinitClips!.pages.map((page, i) => (
                <Fragment key={i}>
                  {page.results.map((clip) => {
                    return <ClipPreview key={clip.id} clip={clip} />
                  })}
                </Fragment>
              ))}
            </div>
            <div className="pb-1" />
            {hasNextPage && !isLoading && (
              <Waypoint
                onEnter={() => {
                  fetchNextPage()
                }}
              />
            )}
            <div className="pb-2" />
            {infinitClips?.pages[0].count === 0 && (
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <div>
                  <img className={styles.ImageNoResults} src={noResults} />
                </div>
                <div className="ms-3 ms-md-5">
                  <div className={styles.TitleNoResults}>Oops!</div>
                  <div>
                    Non abbiamo trovato
                    <br /> nessuna clip con
                    <br /> i filtri applicati.
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {filters.view === 'map' && (
          <Suspense fallback={<LoadingMap />}>
            <MapComponent infinitClips={infinitClips} />
          </Suspense>
        )}
      </div>
      {isLateOnClient && (
        <Suspense fallback={<div />}>
          <WrapClipFiltersCanvas
            filters={filters}
            facets={facets}
            titleCanvas={`${t('cerca_in')} ${territorio.title[i18n.language]}`}
            count={territorio.clips_count || 0}
            countFilter={infinitClips?.pages[0].count || 0}
            handleCloseCanvas={handleCloseCanvas}
            setFilters={setFilters}
            keywords={keywords}
            showCanvas={showCanvas}
          />
        </Suspense>
      )}
    </Layout>
  )
}
