import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { VitaArchivio } from '../types'
import { serializeQueryParams } from './utils'

export async function getViteArchivio(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/vite-archivio/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as VitaArchivio[]
}

export function useViteArchivio(params: Record<string, any> = {}) {
  return useQuery(
    ['vite-archivio', params],
    ({ signal }) => getViteArchivio(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}


export async function getVitaArchivio(idOrSlug: string | number, signal?: AbortSignal) {
  return (await axios.get(`/api/vite-archivio/${idOrSlug}/`, { signal }))
    .data as VitaArchivio
}

export function useVitaArchivio(idOrSlug: string | number) {
  return useQuery(['vita-archivio', idOrSlug], ({ signal }) => getVitaArchivio(idOrSlug, signal))
    .data!
}
