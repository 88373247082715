import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'
import OffcanvasMobileMenu from './components/OffcanvasMobileMenu/OffcanvasMobileMenu'
import { useIsMobileScreen } from './hooks/screen'
import { useIsLateOnClient } from './LateOnClient'

const MobileMenuStateContext = createContext(false)

const MobileMenuActionsContext = createContext<
  Dispatch<SetStateAction<boolean>>
>(null as never)

export function MobileMenuProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false)

  return (
    <MobileMenuActionsContext.Provider value={setOpen}>
      <MobileMenuStateContext.Provider value={open}>
        {children}
      </MobileMenuStateContext.Provider>
    </MobileMenuActionsContext.Provider>
  )
}

export function useMobileMenuToggler() {
  const setOpen = useContext(MobileMenuActionsContext)
  return useCallback(() => {
    setOpen((o) => !o)
  }, [])
}

export function useIsMobileMenuOpen() {
  return useContext(MobileMenuStateContext)
}

export function MobileMenu() {
  const isMobile = useIsMobileScreen()
  const isLateOnClient = useIsLateOnClient()

  if (isMobile && isLateOnClient) {
    return <OffcanvasMobileMenu />
  }

  return null
}
