import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DividerGradient from '../../components/DividerGradient'
import Footer from '../../components/Footer'
import Layout from '../../components/Layout'
import { useContent } from '../../hooks/contents'
import calendar from './assets/home-movies-5x1000.png'
import background from './assets/Immagine_copertina_sito.jpeg'
import styles from './Donate.module.css'

export default function Donate() {
  const donateText = useContent('dona')
  const { i18n, t } = useTranslation()

  function scrollDown() {
    const height = window.innerHeight - 57
    window.scrollTo(0, height)
  }

  return (
    <Layout>
      <div
        className={styles.TopBg}
        style={{ backgroundImage: `url(${background})` }}
      >
        <h3 className={styles.TitleTop}>
          {t('title_donate')}
        </h3>
        <div
          onClick={scrollDown}
          className={`container-icon ${styles.IconToBottom}`}
        >
          <i className="bi bi-arrow-down" />
        </div>
      </div>
      <DividerGradient />
      <div className="container-fluid">
        <div className="h-100 pt-2 pb-2 row content-page">
          <div className="col-xl-6 pt-3 d-flex justify-content-center">
            <img
              className='position-sticky pt-4'
              style={{ top: '50px' }}
              src={calendar}
              height={500}
              // className={'img-fluid'}
              alt="Calendario Home Movies"
            />
          </div>
          <div className={classNames('col-xl-6')}>
            <div className="py-3 py-md-5">
              <h2 className={styles.Title}>
                {donateText.label[i18n.language]}
              </h2>
              <div
                className={`mt-5 ${styles.Description}`}
                dangerouslySetInnerHTML={{
                  __html: donateText.text[i18n.language],
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className={styles.ButtonBuy}
        target="_blank"
        href="https://www.homemovies.it/"
      >
        {t('discover_the_activities_of_foundation_home_movies')}
      </Button>
      <Footer />
    </Layout>
  )
}
