import 'react-bootstrap-typeahead/css/Typeahead.css'
import { forwardRef, Ref, useCallback, useRef, useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'
import { getClipsSuggest } from '../../hooks/clips'
import styles from './SuggestionAutocomplete.module.css'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'

const filterBy = () => true

interface Option {
  text: string
}

interface AutocompleteProps {
  defaultInputValue: string
  onSubmitSearch(search: string): void
}

function SuggestionAutocomplete(
  { defaultInputValue, onSubmitSearch }: AutocompleteProps,
  ref: Ref<Typeahead>
) {
  const { i18n, t } = useTranslation()
  const abortRef = useRef<AbortController | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])

  const handleSearch = useCallback(
    (search: string) => {
      setIsLoading(true)
      if (abortRef.current) {
        abortRef.current.abort()
      }
      abortRef.current = new AbortController()
      return getClipsSuggest(
        {
          search,
          lang: i18n.language,
        },
        abortRef.current.signal
      ).then((options) => {
        setOptions(options)
        setIsLoading(false)
      })
    },
    [i18n.language]
  )

  return (
    <AsyncTypeahead
      ref={ref}
      className={styles.SuggestionAucomplete}
      defaultInputValue={defaultInputValue}
      id="suggestion-search"
      delay={100}
      labelKey="text"
      placeholder={t('cerca_in_archivio') || ''}
      onChange={(selected: any) => {
        if (selected.length > 0) {
          onSubmitSearch(selected[0].text)
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSubmitSearch((e.target as any).value)
        }
      }}
      minLength={3}
      isLoading={false}
      options={options}
      filterBy={filterBy}
      useCache
      onSearch={handleSearch}
    />
  )
}

export default forwardRef(SuggestionAutocomplete)
