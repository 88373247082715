import { useTranslation } from 'react-i18next'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { LANGS } from '../i18n'

function cleanPathname(fullpath: string) {
  let path = fullpath
  const m = matchPath('/:lang/*', path)
  if (m && LANGS.includes(m.params.lang!)) {
    path = '/' + (m.params as Record<string, string>)['*']
  }
  return path === '' ? '/' : path
}

type ChangeLangLinkProps = Omit<Parameters<typeof Link>[0], 'to'> & {
  lang: string
  activeLangClassName?: string
}

export default function ChangeLangLink({
  lang,
  onClick,
  activeLangClassName,
  ...props
}: ChangeLangLinkProps) {
  const { i18n } = useTranslation()
  const location = useLocation()
  const prefix = ''
  const shortLang = lang.split('_')[0]
  const to = `${prefix}/${shortLang}${cleanPathname(location.pathname)}`

  let className = `${props.className ?? ''}`
  if (activeLangClassName && i18n.language === lang) {
    if (className !== '') {
      className += ' '
    }
    className += activeLangClassName
  }

  return (
    <Link
      onClick={(e) => {
        i18n.changeLanguage(lang)
        onClick && onClick(e)
      }}
      {...props}
      className={className}
      to={to}
    />
  )
}
