import { ReactNode } from 'react'
import TopBar from './TopBar'

interface LayoutProps {
  className?: string
  children: ReactNode
  title?: string
  right?: ReactNode
  left?: ReactNode
}

export default function Layout({
  children,
  right,
  left,
  className = '',
}: LayoutProps) {
  return (
    <div className="h-100">
      <TopBar right={right} left={left} />
      <div className={`padding-top-bar h-100 ${className}`}>{children}</div>
    </div>
  )
}
